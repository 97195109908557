import React from "react";
import { Box, Typography, Avatar, Button, Paper, useMediaQuery } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import { useTheme } from "@mui/material/styles";

interface ProfileHeaderProps {
  fullname: string;
  email: string;
  picture?: string;
  role: string;
  onLogout: () => void;
}

const ProfileHeader: React.FC<ProfileHeaderProps> = ({
  fullname,
  email,
  picture,
  role,
  onLogout,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      <Paper
        elevation={4}
        sx={{
          padding: isSmallScreen ? 2 : 4,
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          alignItems: "center",
          gap: isSmallScreen ? 3 : 4,
          borderRadius: "16px",
          boxShadow: theme.shadows[6],
        }}
      >
        <Avatar
          src={picture || ""}
          alt={fullname}
          sx={{
            width: isSmallScreen ? 80 : 120,
            height: isSmallScreen ? 80 : 120,
            boxShadow: theme.shadows[3],
          }}
        />
        <Box sx={{ flexGrow: 1, textAlign: isSmallScreen ? "center" : "left" }}>
          <Typography variant="h5" color={theme.palette.text.primary}>
            Welcome back, <strong>{fullname}</strong>
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            mt={1}
            justifyContent={isSmallScreen ? "center" : "flex-start"}
          >
            <EmailIcon color="action" />
            <Typography variant="body1" color={theme.palette.text.secondary}>
              {email}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            mt={1}
            justifyContent={isSmallScreen ? "center" : "flex-start"}
          >
            <PersonIcon color="action" />
            <Typography variant="body1" color={theme.palette.text.secondary}>
              Role: <strong>{role}</strong>
            </Typography>
          </Box>
        </Box>
        <Button
          variant="contained"
          onClick={onLogout}
          sx={{
            alignSelf: isSmallScreen ? "center" : "flex-start",
            mt: isSmallScreen ? 2 : 0,
            width: isSmallScreen ? "100%" : "auto",
            background: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            "&:hover": {
              background: theme.palette.secondary.dark,
            },
          }}
        >
          Logout
        </Button>
      </Paper>
    </Box>
  );
};

export default ProfileHeader;
