// src/components/LandingPage.tsx
import React from 'react';
import Navbar from './Navbar';
import Hero from './Hero';
import Features from './Features';
import HowItWorks from './HowItWorks';
import Pricing from './Pricing';
import Testimonials from './Testimonials';
import ContactUs from './ContactUs';
import Footer from './Footer';
import TryDemoSection from './TryDemoSection';

const LandingPage: React.FC = () => {
  return (
    <div className="min-h-screen bg-background text-textPrimary">
      <Navbar />
      <section id="home">
        <Hero />
      </section>
      <section id="features">
        <Features />
      </section>
      <section id="how-it-works">
        <HowItWorks />
      </section>
      <section id="try-demo">
        <TryDemoSection />
      </section>
      <section id="pricing">
        <Pricing />
      </section>
      <section id="testimonials">
        <Testimonials />
      </section>
      <section id="contact">
        <ContactUs />
      </section>
      <Footer />
    </div>
  );
};

export default LandingPage;
