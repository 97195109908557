import React from "react";
import Editor from "@monaco-editor/react";
import { Box, useTheme } from "@mui/material";
import { Language, runCodeData } from "../../constants/EditorUtils";

interface EditorPaneProps {
  language: Language;
  theme: string;
  code: string;
  SetCode: React.Dispatch<React.SetStateAction<string>>;
}

const EditorPane: React.FC<EditorPaneProps> = ({
  language,
  theme,
  code,
  SetCode,
}) => {
  const muiTheme = useTheme();
  const isDarkMode = muiTheme.palette.mode === "dark";

  return (
    <Box
      sx={{
        flex: 1,
        backgroundColor: isDarkMode ? "#2e2e2e" : "#ffffff",
        borderRadius: "10px",
        overflow: "hidden", // Prevent overflow
        height: "100%", // Set a fixed height
        width: "100%", // Set a fixed width
      }}
    >
      <Editor
        width="100%"
        height="100%"
        language={language.value}
        theme={theme}
        value={code}
        onChange={(value) => {
          SetCode(value || "");
        }}
        options={{
          selectOnLineNumbers: true,
          minimap: { enabled: false },
          fontSize: 14,
          automaticLayout: true,
          scrollBeyondLastLine: false,
          wordWrap: "on",
          smoothScrolling: true,
        }}
      />
    </Box>
  );
};

export default EditorPane;
