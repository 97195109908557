import React from "react";
import { Typography, Box } from "@mui/material";
import SchoolIcon from "@mui/icons-material/School"; // Cap icon

const Logo = () => {
  return (
    <Box>
      <img
        src="/logo.svg"
        width="auto"
        height="auto"
        alt="Logo"
      />
    </Box>
  );
};

// const Logo = () => {
//   return (
//     <Box
//       display="flex"
//       alignItems="center"
//       sx={{ flexGrow: 1, cursor: "pointer", gap: 1 }}
//     >
//       {/* Cap Icon for the left side */}
//       <SchoolIcon
//         sx={{
//           fontSize: 40, // Adjust size to fit the design
//          color: "secondary.main",
//           marginRight: 0.5, // Spacing adjustment
//         }}
//       />

//       {/* 'CODE' section with primary color background */}
//       <Box
//         sx={{
//           display: "flex",
//           alignItems: "center",
//           bgcolor: "primary.main", // Using primary color for 'CODE'
//           px: 1.5,
//           py: 0.3,
//           borderRadius: "4px 0 0 4px", // Rounded left edges
//         }}
//       >
//         <Typography
//           variant="h6"
//           sx={{
//             fontWeight: "bold",
//             fontFamily: '"Poppins", sans-serif',
//             letterSpacing: 2,
//             color: "#ffffff", // White text for 'CODE'
//           }}
//         >
//           CODE
//         </Typography>
//       </Box>

//       {/* 'CHATURA' section with secondary color text */}
//       <Box
//         sx={{
//           display: "flex",
//           alignItems: "center",
//           bgcolor: "#ffffff", // White background for 'CHATURA'
//           px: 1.5,
//           py: 0.3,
//           borderRadius: "0 4px 4px 0", // Rounded right edges
//           border: "1px solid", // Border using primary color
//           borderColor: "primary.main",
//         }}
//       >
//         <Typography
//           variant="h6"
//           sx={{
//             fontWeight: "bold",
//             fontFamily: '"Poppins", sans-serif',
//             letterSpacing: 2,
//             color: "secondary.main", // Using secondary color for 'CHATURA'
//           }}
//         >
//           CHATURA
//         </Typography>
//       </Box>
//     </Box>
//   );
// };

export default Logo;
