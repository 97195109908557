import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, CircularProgress, Tabs, Tab, Typography } from "@mui/material";
import { ResizableBox } from "react-resizable";
import { apiService } from "../../services/Service";
import ProblemDetails from "./ProblemDetails";
import CodeEditor from "../../components/Editor/CodeEditor";
import Submissions from "./ProblemSubmissionsPage";
import "react-resizable/css/styles.css"; // Import default styles for the resizable component
import { Problem } from "../../constants/ProblemUtils";
import { ErrorResponse } from "../../utils/types";
import { AxiosError } from "axios";
import { handleAuthError } from "../../utils/authUtils";
import { Description, Timelapse } from "@mui/icons-material";

const ProblemPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [problem, setProblem] = useState<Problem | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [tabIndex, setTabIndex] = useState<number>(0); // State for managing the active tab

  useEffect(() => {
    const fetchProblem = async () => {
      try {
        setLoading(true);
        const response = await apiService.get<{ problem: Problem }>(
          `/problem/slug/${slug}`
        );
        setProblem(response.problem);
      } catch (err) {
        setError("Failed to fetch problem details");
        const error = err as AxiosError<ErrorResponse>;
        const errorResponse = error.response?.data;

        if (errorResponse) {
          handleAuthError({ error: errorResponse.error }, window.location.href);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchProblem();
  }, [slug]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  if (error)
    return (
      <Box textAlign="center" color="error.main" mt={4}>
        {error}
      </Box>
    );
  if (!problem) return null;

  return (
    <Box display="flex" height="100vh">
      <ResizableBox
        width={window.innerWidth * 0.5}
        height={Infinity}
        axis="x"
        minConstraints={[window.innerWidth * 0.3, Infinity]}
        maxConstraints={[window.innerWidth * 0.7, Infinity]}
        resizeHandles={["e"]}
        style={{ height: "100%", overflow: "hidden" }}
      >
        <Box sx={{ padding: 1, height: "98vh", overflow: "auto" }}>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="problem details and submissions tabs"
          >
            <Tab label="Description" iconPosition="start" icon={<Description />} />
          <Tab label="Submissions" iconPosition="start" icon={<Timelapse />} />
          </Tabs>
          {tabIndex === 0 && <ProblemDetails problem={problem} />}
          {tabIndex === 1 && <Submissions />}
        </Box>
      </ResizableBox>
      <ResizableBox
        width={window.innerWidth * 0.5}
        height={Infinity}
        axis="x"
        minConstraints={[window.innerWidth * 0.3, Infinity]}
        maxConstraints={[window.innerWidth * 0.7, Infinity]}
        resizeHandles={["w"]}
        style={{ height: "100%", overflow: "hidden" }}
      >
        <Box sx={{ padding: 1, height: "98vh", overflow: "auto" }}>
          <CodeEditor problem={problem} />
        </Box>
      </ResizableBox>
    </Box>
  );
};

export default ProblemPage;
