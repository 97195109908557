import React from "react";
import {
  CheckCircle,
  Error,
  HourglassEmpty,
} from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
// Define a record type to map status strings to React nodes
const statusIcons: Record<string, React.ReactNode> = {
  "In Queue": <HourglassEmpty color="primary" />,
  "Processing": <CircularProgress color="primary" size={20} />,
  "Accepted": <CheckCircle color="success" />,
  "Wrong Answer": <Error color="error" />,
  "Time Limit Exceeded": <Error color="error" />,
  "Compilation Error": <Error color="error" />,
  "Runtime Error (SIGSEGV)": <Error color="error" />,
  "Runtime Error (SIGXFSZ)": <Error color="error" />,
  "Runtime Error (SIGFPE)": <Error color="error" />,
  "Runtime Error (SIGABRT)": <Error color="error" />,
  "Runtime Error (NZEC)": <Error color="error" />,
  "Runtime Error (Other)": <Error color="error" />,
  "Internal Error": <Error color="error" />,
  "Exec Format Error": <Error color="error" />,
};

export default statusIcons;
