import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  List,
  ListItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Group, Assessment, MonetizationOn, Star } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { apiService } from "../services/Service";

const PricingCard = styled(Card)(({ theme }) => ({
  borderRadius: "12px",
  boxShadow: theme.shadows[6],
  display: "flex",
  flexDirection: "column",
  height: "100%",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow: theme.shadows[10],
  },
}));

const PricingCardContent = styled(CardContent)(({ theme }) => ({
  flexGrow: 1,
  textAlign: "center",
  color: theme.palette.text.primary,
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.background.paper,
  borderRadius: "50%",
  width: 80,
  height: 80,
  margin: "0 auto",
  marginBottom: theme.spacing(2),
}));

const Pricing: React.FC = () => {
  const theme = useTheme();
  const [activePlans, setActivePlans] = useState<any>([]);
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    apiService
      .get("/plans/active/me")
      .then((response: any) => {
        setActivePlans(response.plans);
      })
      .catch((error) => {
        console.error("Error fetching active plans:", error);
      });
  }, []);

  const openModal = (plan: any) => {
    setSelectedPlan(plan);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedPlan(null);
    setIsModalOpen(false);
  };

  return (
    <Box sx={{ backgroundColor: theme.palette.background.default }} py={10} id="pricing">
      <Container>
        <Typography variant="h3" align="center" fontWeight="bold" gutterBottom color={theme.palette.text.primary}>
          Pricing Plans
        </Typography>
        <Typography
          variant="h6"
          align="center"
          paragraph
          color={theme.palette.text.secondary}
        >
          Choose the plan that best fits your institution's needs.
        </Typography>
        <Grid container spacing={4} mt={4} alignItems="stretch">
          {activePlans.map((plan: any) => (
            <Grid item xs={12} md={4} key={plan._id}>
              <PricingCard>
                <PricingCardContent>
                  <IconWrapper>
                    {plan.title === "Basic" ? <Star fontSize="large" /> :
                      plan.title === "Pro" ? <MonetizationOn fontSize="large" /> :
                        <Assessment fontSize="large" />}
                  </IconWrapper>
                  <Typography variant="h5" fontWeight="bold" gutterBottom color={theme.palette.text.primary}>
                    {plan.title} Plan
                  </Typography>
                  <Typography
                    variant="h4"
                    color={theme.palette.primary.main}
                    fontWeight="bold"
                    gutterBottom
                  >
                    {plan.price.usd > 0
                      ? `$${plan.price.usd} / ₹${plan.price.inr}`
                      : "Free"}
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <List>
                    <ListItem sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Group color="primary" />
                      <Typography variant="body1">
                        <strong>Users:</strong> {plan.numberOfUsers}
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Assessment color="primary" />
                      <Typography variant="body1">
                        <strong>Assessments:</strong> {plan.numberOfAssessments}
                      </Typography>
                    </ListItem>
                  </List>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                      "&:hover": {
                        backgroundColor: theme.palette.primary.dark,
                      },
                    }}
                    size="large"
                    fullWidth
                    onClick={() => openModal(plan)}
                  >
                    View Details
                  </Button>
                </PricingCardContent>
              </PricingCard>
            </Grid>
          ))}
        </Grid>

        <Typography variant="h5" align="center" mt={6} color={theme.palette.text.primary}>
          For custom plans, <strong>Contact Sales</strong>:
        </Typography>
        <Typography variant="h6" align="center" color={theme.palette.text.secondary}>
          Email: admin@codechatura.com | Phone: +91 9959950082
        </Typography>
      </Container>

      {/* Modal for Plan Details */}
      {selectedPlan && (
        <Dialog open={isModalOpen} onClose={closeModal} maxWidth="md" fullWidth>
          <DialogTitle>{selectedPlan.title} Plan Details</DialogTitle>
          <DialogContent>
            <Typography dangerouslySetInnerHTML={{ __html: selectedPlan.details }} />
            <Typography mt={2}>
              <strong>Start Date:</strong> {new Date(selectedPlan.startDate).toLocaleDateString()}
            </Typography>
            <Typography>
              <strong>End Date:</strong> {new Date(selectedPlan.endDate).toLocaleDateString()}
            </Typography>
            <Typography>
              <strong>Levels:</strong> {selectedPlan.levels.join(", ")}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal} variant="outlined">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default Pricing;
