import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { SnackbarMessage } from '../customHooks/useSnackbar';

interface CustomSnackbarProps {
  open: boolean;
  messageInfo: SnackbarMessage | null;
  autoHideDuration?: number;
  handleClose: () => void;
}

const CustomSnackbar: React.FC<CustomSnackbarProps> = ({
  open,
  messageInfo,
  autoHideDuration = 6000,
  handleClose,
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
    >
      {messageInfo ? (
        <Alert
          onClose={handleClose}
          severity={messageInfo.type}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {messageInfo.message}
        </Alert>
      ) : undefined /* Return undefined instead of null */}
    </Snackbar>
  );
};

export default CustomSnackbar;
