// UserContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';
import { UserRole } from '../utils/types';

// Define the type for the user details
interface User {
  id: string;
  fullname: string;
  email: string;
  picture: string;
  role: UserRole;
}

// Define the type for the context state, including the user and the updater function
interface UserContextType {
  user: User | null;
  setUser: (user: User | null) => void;
}

// Create the context with default values
const UserContext = createContext<UserContextType | undefined>(undefined);

// Custom hook to use the UserContext
export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

// Provider component that wraps your app and provides user state
export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};
