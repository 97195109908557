import React from "react";
import { Box, Container, Typography, Grid, Button, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import QuizIcon from "@mui/icons-material/Quiz";
import CodeIcon from "@mui/icons-material/Code";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const SectionContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(6, 0),
  background: theme.palette.background.default,
  textAlign: "center",
  height: "90vh",
  marginBottom: theme.spacing(10),
  overflow: "hidden",
  position: "relative",
}));

const DemoCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  transition: "transform 0.3s ease-in-out",
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: theme.shadows[6],
  },
}));

const DemoButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  fontSize: "1rem",
  padding: theme.spacing(1.5, 4),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const TryDemoSection: React.FC = () => {
  const theme = useTheme();

  return (
    <SectionContainer>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold", color: theme.palette.text.primary }}>
          Try Our Demo Assessments
        </Typography>
        <Typography
          variant="subtitle1"
          paragraph
          sx={{ color: theme.palette.text.secondary, marginBottom: 4 }}
        >
          Experience the power of CodeChatura's assessment platform. Choose
          between a coding test or an MCQ test to get started!
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <DemoCard>
              <QuizIcon
                color="primary"
                sx={{ fontSize: 80, marginBottom: 2 }}
              />
              <Typography variant="h5" sx={{ fontWeight: "bold", color: theme.palette.primary.main }}>
                Try MCQ Test
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 2 }}>
                Challenge yourself with a variety of multiple-choice questions
                across different topics.
              </Typography>
              <Link to="/demo/mcq-test" style={{ textDecoration: 'none' }}>
                <DemoButton variant="contained">
                  Start MCQ Test
                </DemoButton>
              </Link>
            </DemoCard>
          </Grid>
          <Grid item xs={12} sm={6}>
            <DemoCard>
              <CodeIcon
                color="secondary"
                sx={{ fontSize: 80, marginBottom: 2 }}
              />
              <Typography variant="h5" sx={{ fontWeight: "bold", color: theme.palette.primary.main }}>
                Try Coding Test
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 2 }}>
                Dive into coding challenges and put your programming skills to
                the test.
              </Typography>
              <Link to="#coding-test" style={{ textDecoration: 'none' }}>
                <DemoButton variant="contained">
                  Start Coding Test
                </DemoButton>
              </Link>
            </DemoCard>
          </Grid>
        </Grid>
      </Container>
    </SectionContainer>
  );
};

export default TryDemoSection;
