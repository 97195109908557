import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
  Avatar,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import HomeIcon from "@mui/icons-material/Home";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import StarIcon from "@mui/icons-material/Star";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import { useThemeContext } from "../contextApi/ThemeContext";
import Logo from "./Logo";

const Navbar: React.FC = () => {
  const { toggleTheme, mode } = useThemeContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<{ name: string; picture: string } | null>(
    null
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const token = localStorage.getItem("jwt_token");
    if (token) {
      setIsAuthenticated(true);
      const userData = localStorage.getItem("user");
      if (userData) {
        setUser(JSON.parse(userData));
      }
    }
  }, []);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    window.location.href = "/profile";
  };

  return (
    <AppBar
      position="sticky"
      sx={{ backgroundColor: theme.palette.background.default }}
    >
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <Logo />
        </Box>
        {!isMobile ? (
          <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
            <Button color="primary" href="#home" startIcon={<HomeIcon />}>
              Home
            </Button>
            <Button color="primary" href="#try-demo" startIcon={<PlayCircleFilledIcon />}>
              Try Demo
            </Button>
            <Button color="primary" href="#features" startIcon={<StarIcon />}>
              Features
            </Button>
            <Button color="primary" href="#pricing" startIcon={<AttachMoneyIcon />}>
              Pricing
            </Button>
            <Button color="primary" href="#contact" startIcon={<ContactMailIcon />}>
              Contact
            </Button>
            <IconButton
              onClick={toggleTheme}
              sx={{
                color:
                  mode === "light"
                    ? theme.palette.primary.main
                    : theme.palette.secondary.main,
                backgroundColor:
                  mode === "light"
                    ? theme.palette.grey[200]
                    : theme.palette.grey[800],
                "&:hover": {
                  backgroundColor:
                    mode === "light"
                      ? theme.palette.grey[300]
                      : theme.palette.grey[700],
                },
              }}
            >
              {mode === "light" ? <DarkModeIcon /> : <LightModeIcon />}
            </IconButton>
            {isAuthenticated ? (
              <Avatar
                alt={user?.name}
                src={user?.picture}
                onClick={handleProfileClick}
                sx={{ cursor: "pointer" }}
              />
            ) : (
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: "black",
                  color: theme.palette.secondary.main,
                }}
                href="/login"
              >
                Login
              </Button>
            )}
          </Box>
        ) : (
          <>
            <IconButton
              edge="end"
              color="primary"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              sx={{ ml: "auto" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
              PaperProps={{
                sx: {
                  width: "200px",
                  borderRadius: "8px",
                  mt: "45px",
                },
              }}
            >
              <MenuItem onClick={handleClose} component="a" href="#home">
                <HomeIcon sx={{ mr: 1 }} />
                Home
              </MenuItem>
              <MenuItem onClick={handleClose} component="a" href="#try-demo">
                <PlayCircleFilledIcon sx={{ mr: 1 }} />
                Try Demo
              </MenuItem>
              <MenuItem onClick={handleClose} component="a" href="#features">
                <StarIcon sx={{ mr: 1 }} />
                Features
              </MenuItem>
              <MenuItem onClick={handleClose} component="a" href="#pricing">
                <AttachMoneyIcon sx={{ mr: 1 }} />
                Pricing
              </MenuItem>
              <MenuItem onClick={handleClose} component="a" href="#contact">
                <ContactMailIcon sx={{ mr: 1 }} />
                Contact
              </MenuItem>
              {isAuthenticated ? (
                <MenuItem onClick={handleProfileClick}>
                  <Avatar
                    alt={user?.name}
                    src={user?.picture}
                    sx={{ marginRight: "8px", cursor: "pointer" }}
                  />
                  {user?.name}
                </MenuItem>
              ) : (
                <MenuItem onClick={handleClose} component="a" href="/login">
                  Login
                </MenuItem>
              )}
              <MenuItem onClick={toggleTheme}>
                {mode === "light" ? <DarkModeIcon sx={{ mr: 1 }} /> : <LightModeIcon sx={{ mr: 1 }} />}
                Toggle Theme
              </MenuItem>
            </Menu>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
