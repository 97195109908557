import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import { apiService } from "../../../services/Service";
import { AxiosError } from "axios";
import { ErrorResponse } from "../../../utils/types";
import {
  Container,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
  TablePagination,
  InputAdornment,
  IconButton,
  Button,
  Modal,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { handleAuthError } from "../../../utils/authUtils";
import { Link } from "react-router-dom";

const ManagePlans: React.FC = () => {
  const [plans, setPlans] = useState<any>([]);
  const [totalPlans, setTotalPlans] = useState(0); // Total count of plans from backend
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<any | null>(null);
  // Styling for the modal (you can customize it as needed)
  const modalStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  // Function to open the modal with selected plan details
  const handleOpen = (plan: any) => {
    setSelectedPlan(plan);
    setOpen(true);
  };

  // Function to close the modal
  const handleClose = () => {
    setOpen(false);
    setSelectedPlan(null);
  };

  useEffect(() => {
    const fetchPlans = async () => {
      setLoading(true);
      try {
        const queryParams = new URLSearchParams({
          page: currentPage.toString(),
          limit: rowsPerPage.toString(),
          search: search || "",
        });

        const url = `/plans?${queryParams.toString()}`;
        const response: any = await apiService.get<{ data: any; pages: number }>(url);
        setPlans(response.data);
        setTotalPlans(response.total); // Use total plans count from backend
      } catch (err) {
        const error = err as AxiosError<ErrorResponse>;
        const errorResponse = error.response?.data;

        if (errorResponse) {
          handleAuthError({ error: errorResponse.error }, window.location.href);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchPlans();
  }, [currentPage, search, rowsPerPage]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setCurrentPage(newPage + 1); // MUI's TablePagination is 0-indexed
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  return (
    <Container>
      <Box mb={4} width="100%" display="flex" flexDirection="column" gap={2}>
        <Box display="flex" gap={2} flexDirection={{ xs: "column", sm: "row" }}>
          <TextField
            variant="outlined"
            placeholder="Search plans..."
            value={search}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setSearch("")} edge="end">
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ flex: 1 }}
          />
        </Box>
      </Box>

      {/* Plan List Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Details</TableCell>
              <TableCell>No OF Users</TableCell>
              <TableCell>No Of Assesments</TableCell>
              <TableCell>Price(INR)</TableCell>
              <TableCell>Price(USD)</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : plans.length > 0 ? (
              plans.map((plan: any) => (
                <TableRow key={plan._id}>
                  <TableCell>{plan.title}</TableCell>
                  <TableCell>
                    <Button onClick={() => handleOpen(plan)}>Show Details</Button>
                  </TableCell>
                  <TableCell>{plan.numberOfUsers}</TableCell>
                  <TableCell>{plan.numberOfAssessments}</TableCell>
                  <TableCell>{plan.price.inr}</TableCell>
                  <TableCell>{plan.price.usd}</TableCell>
                  <TableCell>
                    <Button
                      component={Link} // Set the underlying component to 'Link'
                      to={`/plans/update/${plan._id}`}
                      variant="contained"
                      color="primary"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Typography variant="subtitle1" fontWeight="bold" sx={{ color: "white" }}>
                        Edit Plan
                      </Typography>
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No plans found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination Controls */}
      <Box display="flex" justifyContent="center" mt={4}>
        <TablePagination
          component="div"
          count={totalPlans} // Use total plan count from backend
          rowsPerPage={rowsPerPage}
          page={currentPage - 1} // MUI's TablePagination is 0-indexed
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPageOptions={[5, 10, 25, 50]}
          labelRowsPerPage="Plans per page"
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`
          }
        />
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography variant="h6" id="modal-title">
            Plan Details
          </Typography>
          <Typography variant="body1" id="modal-description">
           <div dangerouslySetInnerHTML={{ __html: selectedPlan?.details || '' }}/>
          </Typography>
          <Button onClick={handleClose} sx={{ marginTop: 2 }}>
            Close
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};

export default ManagePlans;
