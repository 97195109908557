export  const languages = [
    {
      label: "JavaScript",
      value: "javascript",
      id: 93,
      defaultCode: `console.log("Hello, World!");`,
    },
    {
      label: "C",
      value: "c",
      id: 75,
      defaultCode: `#include <stdio.h>\n\nint main() {\n    printf("Hello, World!");\n    return 0;\n}`,
    },
    {
      label: "C++",
      value: "cpp",
      id: 54,
      defaultCode: `#include <iostream>\n\nint main() {\n    std::cout << "Hello, World!";\n    return 0;\n}`,
    },
    {
      label: "Java",
      value: "java",
      id: 91,
      defaultCode: `public class Main {\n    public static void main(String[] args) {\n        System.out.println("Hello, World!");\n    }\n}`,
    },
    {
      label: "Python",
      value: "python",
      id: 71,
      defaultCode: `print("Hello, World!")`,
    },
  ];
export const themes = ['vs-light', 'vs-dark', 'hc-black', 'monokai'];