import React from "react";
import RichTextEditor from "./RichTextEditor";
import { Typography, Grid, TextField } from "@mui/material";

interface RichTextFieldProps {
  label: string;
  value: string;
  onChange: (content: string) => void;
}

const RichTextField: React.FC<RichTextFieldProps> = ({ label, value, onChange }) => {
  return (
    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom>
        {label}
      </Typography>
      <RichTextEditor value={value} onChange={onChange} />
    </Grid>
  );
};

export default RichTextField;
