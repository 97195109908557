import React from "react";
import { FormControl, InputLabel, Select, MenuItem, useTheme } from "@mui/material";
import { SelectChangeEvent } from "@mui/material";
import { themes } from "../../constants/EditorConstants";

interface ThemeSelectorProps {
  theme: string;
  onChange: (event: SelectChangeEvent<string>) => void;
}

const ThemeSelector: React.FC<ThemeSelectorProps> = ({ theme, onChange }) => {
  const themeContext = useTheme();
  const isDarkMode = themeContext.palette.mode === "dark";

  return (
    <FormControl fullWidth>
      <InputLabel
        sx={{
          color: isDarkMode ? "#ffffff" : "#000000",
        }}
      >
        Theme
      </InputLabel>
      <Select
        value={theme}
        onChange={onChange}
        sx={{
          borderRadius: "10px",
          backgroundColor: isDarkMode ? "#424242" : "#ffffff",
          color: isDarkMode ? "#ffffff" : "#000000",
          '& .MuiSelect-icon': {
            color: isDarkMode ? "#ffffff" : "#000000",
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: isDarkMode ? "#ffffff" : "#000000",
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: isDarkMode ? "#ffffff" : "#000000",
          },
        }}
      >
        {themes.map((th) => (
          <MenuItem key={th} value={th}>
            {th}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ThemeSelector;
