import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ThemeContextProvider  from "./contextApi/ThemeContext";
import { UserProvider } from "./contextApi/UserContext";

const clientId =
  "993064650976-ast0epcsv138dg1711md2tso7t7uh7vq.apps.googleusercontent.com";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={clientId}>
      <ThemeContextProvider> {/* Use ThemeContextProvider here */}
        <UserProvider>
          <App />
        </UserProvider>
      </ThemeContextProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
