import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ThemeContextProvider from "./contextApi/ThemeContext";
import Login from "./pages/Login";
import NotFoundPage from "./components/404Page";
import LandingPage from "./components/LandingPage"; // Import the landing page
import Dashboard from "./components/dashboards/BaseDashBoard"; // Common dashboard layout
import ProfileDashboard from "./pages/Profile";
import UserManagement from "./pages/admin/users/users";
import Problems from "./pages/author/problem/problems";
import CreateProblem from "./pages/author/problem/createProblem";
import CreateMCQ from "./pages/author/mcq/createMcq";
import MCQs from "./pages/author/mcq/problems";
import ClientList from "./pages/admin/client/ManageClients";
import CreateClient from "./pages/admin/client/CreateClient";
import UpdateClient from "./pages/admin/client/UpdateClient";
import UpdateMCQ from "./pages/author/mcq/updateMcq";
import UpdateProblem from "./pages/author/problem/updateProblem";
import ClientDetails from "./pages/client/ClientDetails";
import ClientUserManagement from "./pages/client/UserManagement";
import Assessments from "./pages/assessment/Assessments";
import CreateAssessment from "./pages/assessment/CreateAssessment";
import ProblemPage from "./pages/problem/ProblemPage";
import UpdateAssessment from "./pages/assessment/UpdateAssessment";
import CreateUser from "./pages/admin/users/CreateUser";
import AssessmentDetailsPage from "./pages/assessment/AssessmentEnv/AssessmentDetailsPage";
import AssessmentHomePage from "./pages/assessment/AssessmentEnv/AssessmentHomePage";
import ManagePlans from "./pages/admin/plans/ManagePlans";
import CreatePlan from "./pages/admin/plans/CreatePlan";
import UpdatePlan from "./pages/admin/plans/UpdatePlan";


const App: React.FC = () => {
  return (
    <ThemeContextProvider>
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            {/* Landing Page Route */}
            <Route path="/" element={<LandingPage />} />

            {/* Protected Routes (only those that need the dashboard layout) */}
            <Route element={<Dashboard />}>
              <Route path="profile" element={<ProfileDashboard />} />
              <Route path="users" element={<UserManagement />} />
              <Route path="users/create" element={<CreateUser />} />
              <Route path="coding-problems" element={<Problems />} />
              <Route path="coding-problems/create" element={<CreateProblem />} />
              <Route path="mcq-problems" element={<MCQs />} />
              <Route path="mcq-problems/create" element={<CreateMCQ />} />
              <Route path="mcq/update/:mcqId" element={<UpdateMCQ />} />
              <Route path="clients" element={<ClientList />} />
              <Route path="clients/create" element={<CreateClient />} />
              <Route path="problem/update/:problemId" element={<UpdateProblem />} />
              <Route path="admin/client/update/:id" element={<UpdateClient />} />
              <Route path="subscription/details" element={<ClientDetails/>} />
              <Route path="subscription/users" element={<ClientUserManagement/>} />
              <Route path="assessments" element={<Assessments/>} />
              <Route path="assessments/create" element={<CreateAssessment/>} />
              <Route path="assessments/update/:id" element={<UpdateAssessment/>} />
              <Route path="plans" element={<ManagePlans/>} /> 
              <Route path="plans/create" element={<CreatePlan/>} />
              <Route path="plans/update/:id" element={<UpdatePlan/>} />
             

            </Route>
            <Route path="/problem/slug/:slug" element={<ProblemPage />} />

            {/* Login Route */}
            <Route path="/login" element={<Login />} />
            <Route path="/assessment/details/:slug" element={<AssessmentDetailsPage />} />
            <Route path="/assessment/:assessmentslug" element={<AssessmentHomePage />} />

            {/* Catch-all Route for Not Found */}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
      </Router>
    </ThemeContextProvider>
  );
};

export default App;
