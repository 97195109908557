export const tagOptions = ["Array",
    "String",
    "Linked List",
    "Stack",
    "Queue",
    "Hash Table",
    "Sorting",
    "Searching",
    "Recursion",
    "Mathematical coding problems",
    "Matrices",
    "Dynamic Programming",
    "Greedy Algorithms",
    "Graphs",
    "Trees",
    "Binary Tree",
    "Binary Search Tree (BST)",
    "Heap",
    "Trie",
    "prefix sum",
    "map",
    "seive",
    "loops",
    "set",
    "counting",
    "game theory",
    "Segment Tree",
    "Fenwick Tree (Binary Indexed Tree)",
    "Disjoint Set Union (DSU)",
    "Depth-First Search (DFS)",
    "Breadth-First Search (BFS)",
    "Shortest Path Algorithms",
    "Minimum Spanning Tree (MST)",
    "Topological Sorting",
    "Bipartite Graphs",
    "Network Flow Algorithms",
    "String Matching",
    "Pattern Searching",
    "Subsequence",
    "Palindrome",
    "Knuth-Morris-Pratt (KMP) Algorithm",
    "Rabin-Karp Algorithm",
    "Z Algorithm",
    "Manacher's Algorithm",
    "Graph Traversal",
    "Tree Traversal",
    "Graph Representation",
    "Tree Representation",
    "Graph Algorithms",
    "Tree Algorithms",
    "Space and Time Complexity",
    "Big O Notation",
    "Divide and Conquer",
    "Backtracking",
    "Sliding Window Technique",
    "Two Pointers Technique",
    "Bit Manipulation",
    "Binary Lifting",
    "Math and Number Theory",
    "Geometry Algorithms",];