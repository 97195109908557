import { ProblemStatus, ProgrammingLanguage } from "../utils/types"; // Ensure this is correctly imported
import { Author } from "./ProblemsPageUtils";

// Define the structure of a test case
export interface Testcase {
    input: string;
    output: string;
    explanation?: string;
}

// Define the structure of the problem form data
export interface ProblemFormData {
  title: string;
  slug: string;
  description: string;
  inputFormat: string;
  outputFormat: string;
  publicTestcases: Testcase[];
  privateTestcases: Testcase[];
  difficultyLevel: string;
  constraints: string;
  tags: string[];
  status: ProblemStatus;
  defaultCode: DefaultCode[]; // Added defaultCode property to ProblemFormData
  levels: string[];
}

// Define the structure of default code for different programming languages
export interface DefaultCode {
  language: ProgrammingLanguage;
  code: string;
}

// Define the structure of a problem
export interface Problem {
  title: string;
  slug: string;
  tags: string[];
  difficultyLevel: string;
  description: string;
  constraints: string;
  inputFormat: string;
  outputFormat: string;
  publicTestcases: Testcase[];
  defaultCode: DefaultCode[];
  author: Author;
  levels: string[];
}

// Initial data structure for a problem form
export const initialData: ProblemFormData = {
  title: "",
  slug: "",
  description: "",
  inputFormat: "",
  outputFormat: "",
  publicTestcases: [{ input: "", output: "", explanation: "" }],
  privateTestcases: [{ input: "", output: "", explanation: "" }],
  difficultyLevel: "",
  constraints: "",
  tags: [],
  defaultCode: [
    { language: ProgrammingLanguage.JAVASCRIPT, code: `console.log("Hello, World!");` },
    { language: ProgrammingLanguage.C, code: `#include <stdio.h>\n\nint main() {\n    printf("Hello, World!");\n    return 0;\n}` },
    { language: ProgrammingLanguage.CPP, code: `#include <iostream>\n\nint main() {\n    std::cout << "Hello, World!";\n    return 0;\n}` },
    { language: ProgrammingLanguage.JAVA, code: `public class Main {\n    public static void main(String[] args) {\n        System.out.println("Hello, World!");\n    }\n}` },
    { language: ProgrammingLanguage.PYTHON, code: `print("Hello, World!")` },
  ],
  status: ProblemStatus.DRAFT,
  levels: ["LV4"],
};
