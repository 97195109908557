import React, { useCallback, useEffect, useState } from "react";
import { Paper, Box, Grid, useTheme, Button, IconButton, Tooltip, Tabs, Tab, CircularProgress, Typography, Divider } from "@mui/material";
import LanguageSelector from "./LanguageSelector";
import ThemeSelector from "./ThemeSelector";
import CustomFields from "./CustomFields";
import EditorPane from "./EditorPane";
import { SelectChangeEvent } from "@mui/material";
import { Language, runCodeData } from "../../constants/EditorUtils";
import { languages } from "../../constants/EditorConstants";
import { CheckCircle, PlayArrow, Refresh } from "@mui/icons-material";
import { apiService } from "../../services/Service";
import { useParams } from "react-router-dom";
import statusIcons from "../../constants/statusIcons";
import { Close, Error } from "@mui/icons-material";
interface CodeStatusResponse {
  status: string;
  stdout?: string;
  stderr?: string;
  message?: string;
  output?: string;
  compile_output?: string;
}

interface RunCodeResponse {
  submissionId: string;
}

interface SubmitCodeType {
  languageId: number;
  code: string;
  problemSlug: string;
  language: string;
  assessmentSlug?: string;
}
const CodeEditor: React.FC<{ problem: any }> = ({ problem }) => {
  const theme = useTheme(); // Access the current theme
  const isDarkMode = theme.palette.mode === "dark"; // Check if the current theme mode is dark
  const [activeTab, setActiveTab] = useState<number>(0);
  const [submissionId, setSubmissionId] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [result, setResult] = useState<string>("");
  const [language, setLanguage] = useState<Language>({
    label: "C++",
    value: "cpp",
    id: 54,
  });
  const [code, setCode] = useState<string>(
    problem.defaultCode.find((defaultCode: any) => defaultCode.language === language.value)?.code || ""
  );
  const [themeValue, setThemeValue] = useState<string>("vs-dark");
  const [customInput, setCustomInput] = useState<string>(problem?.publicTestcases?.[0]?.input || "");
  const [runningCode, setRunningCode] = useState<boolean>(false);
  const [testcaseResults, setTestcaseResults] = useState<string[]>([]);
  const [submissionStatus, setSubmissionStatus] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [submitsubmissionId, setsubmitSubmissionId] = useState<string>("");
  const { assessmentslug } = useParams<{ assessmentslug: string }>();

  // Poll submission status
  const pollSubmission = useCallback(() => {
    if (!submissionId) return;

    let timeoutId: NodeJS.Timeout;
    apiService
      .get<CodeStatusResponse>(`/code/status/${submissionId}`)
      .then((res) => {
        const { status, stdout, stderr, message, output, compile_output } = res;
        setStatus(status);

        if (status === "In Queue" || status === "Processing") {
          timeoutId = setTimeout(pollSubmission, 1000);
        } else {
          setRunningCode(false);
          setResult(
            compile_output || output || stdout || stderr || message || "No output"
          );
        }
      })
      .catch((err: any) => {
        const errorMessage = err?.response?.data?.message || "An error occurred.";
        console.error(errorMessage);
        setStatus("Error");
        setResult(errorMessage);
        setRunningCode(false);
      });

    return () => clearTimeout(timeoutId); // Clean up timeouts
  }, [submissionId]);

  // Submit and run the code
  const runCode = useCallback(() => {
    setRunningCode(true);
    setActiveTab(1);

    const dataForRun = {
      language_id: language.id,
      source_code: code,
      stdin: customInput,
    };

    apiService
      .post<RunCodeResponse>("/code/run", dataForRun)
      .then((res) => {
        setSubmissionId(res.submissionId);
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.message || "Failed to submit your code.";
        console.error(errorMessage);
        setStatus("Error");
        setResult(errorMessage);
        setRunningCode(false);
      });
  }, [language.id, code, customInput]);

  // Trigger polling when submissionId changes
  useEffect(() => {
    pollSubmission();
  }, [pollSubmission]);



  const submitCode = useCallback(() => {
    setIsSubmitting(true);
    setRunningCode(true);
    setActiveTab(1);
    setResult("");
    setStatus("");

    const dataForSubmit: SubmitCodeType = {
      languageId: language.id,
      code: code,
      problemSlug: problem.slug || "",
      language: language.value,
      assessmentSlug: assessmentslug
    };


    apiService
      .post("/submit", dataForSubmit)
      .then((res: any) => {
        const { submissionId } = res;
        setSubmissionStatus("Processing");
        setsubmitSubmissionId(submissionId);
        setHasSubmitted(true);
      })
      .catch((err) => {
        console.error(err);
        setStatus("Error");
        setResult("Failed to submit your code.");
        setRunningCode(false);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }, [code, language.id, isSubmitting, hasSubmitted]);

  useEffect(() => {
    if (!submitsubmissionId) return;

    const pollSubmissionStatus = () => {
      apiService
        .get(`/submit/status/${submitsubmissionId}`)
        .then((res: any) => {
          const { submissionStatuses, overallStatus } = res;
          setSubmissionStatus(overallStatus);
          setTestcaseResults(submissionStatuses);

          if (overallStatus === "In Queue" || overallStatus === "Processing") {
            setTimeout(pollSubmissionStatus, 3000); // Poll every 3 seconds
          } else {
            setResult(submissionStatuses.join("\n") || "No output");
            setRunningCode(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setSubmissionStatus("Error");
          setResult("An error occurred while processing your code.");
          setRunningCode(false);
        });
    };

    pollSubmissionStatus();
  }, [submitsubmissionId]);


  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    const selectedLanguage: Language = {
      label: event.target.value,
      value: event.target.value,
      id: languages.find((lang) => lang.value === event.target.value)?.id || 54,
    };

    const newCode =
      problem.defaultCode.find((defaultCode: any) => defaultCode.language === selectedLanguage.value)?.code ||
      "";

    setLanguage(selectedLanguage);
    setCode(newCode);
  };

  const handleThemeChange = (event: SelectChangeEvent<string>) => {
    setThemeValue(event.target.value as string);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomInput(event.target.value);
  };



  const resetCode = () => {
    console.log("Reset code");
  };
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };


  return (
    <Paper
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        p: 2,
        background: isDarkMode
          ? "linear-gradient(135deg, #2e2e2e, #1c1c1c)"
          : "linear-gradient(135deg, #f3f4f7, #e9ebf3)",
        boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
      }}
    >
      {/* Controls Section */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2,
          mb: 2,
        }}
      >
        {/* Run and Submit Buttons */}
        <Box
          sx={{
            display: "flex",
            gap: 1,
          }}
        >
          <Button
            variant="contained"
            onClick={runCode}
            disabled={code === "" || code.trim() === ""}
            startIcon={<PlayArrow />}
            sx={{
              color: "#ffffff",
              backgroundColor: isDarkMode ? "#4caf50" : "#1976d2",
              '&:disabled': {
                backgroundColor: isDarkMode ? "#666666" : "#cccccc",
              },
            }}
          >
            Run
          </Button>
          <Button
            variant="contained"
            onClick={() => { submitCode() }}
            disabled={code === "" || code.trim() === ""}
            startIcon={<CheckCircle />}
            sx={{
              color: "#ffffff",
              backgroundColor: isDarkMode ? "#4caf50" : "#388e3c",
              '&:disabled': {
                backgroundColor: isDarkMode ? "#666666" : "#cccccc",
              },
            }}
          >
            Submit
          </Button>
        </Box>

        {/* Reset Code with Tooltip */}
        <Tooltip title="Reset Code" arrow>
          <IconButton
            onClick={resetCode}
            sx={{
              color: isDarkMode ? "#ffffff" : "#000000",
              backgroundColor: isDarkMode ? "#333333" : "#f5f5f5",
              '&:hover': {
                backgroundColor: isDarkMode ? "#444444" : "#e0e0e0",
              },
            }}
          >
            <Refresh />
          </IconButton>
        </Tooltip>

        {/* Language and Theme Selectors */}
        <Box
          sx={{
            display: "flex",
            gap: 2,
            alignItems: "center",
            flexGrow: 1,
            justifyContent: "flex-end",
          }}
        >
          <LanguageSelector
            language={language}
            onChange={handleLanguageChange}
          />
          <ThemeSelector
            theme={themeValue}
            onChange={handleThemeChange}
          />
        </Box>
      </Box>

      {/* Editor Section */}
      <Box
        sx={{
          flexGrow: 1,
          mb: 2,
          overflow: "hidden",
          borderRadius: "10px",
        }}
      >
        <EditorPane
          code={code}
          SetCode={setCode}
          language={language}
          theme={themeValue}
        />
      </Box>

      {/* Custom Input Fields */}
      <Box>
        {/* Tabs Navigation */}
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="Input and Result Tabs"
          variant="fullWidth"
          textColor="primary"
          indicatorColor="primary"
          sx={{ mb: 2 }}
        >
          <Tab label="Input" />
          <Tab label="Result" />
        </Tabs>

        {/* Tab Content */}
        <Box
          sx={{
            flexGrow: 1,
            mb: 2,
            mt: 2,
            overflow: "hidden",
            borderRadius: "10px",
          }}
        >
          <Box
            role="tabpanel"
            hidden={activeTab !== 0}
            sx={{
              display: activeTab === 0 ? "block" : "none", height: "200px",
              overflow: "auto",
            }}

          >
            <CustomFields customInput={customInput} onInputChange={handleInputChange} />
          </Box>
          <Box
            role="tabpanel"
            hidden={activeTab !== 1}
            sx={{
              display: activeTab === 1 ? "block" : "none",
              textAlign: "center",
              p: 0.5,
              height: "200px",
              overflow: "auto",
            }}
          >
            <Box
              sx={{
                bgcolor: isDarkMode ? "#333" : "#fff",
                color: isDarkMode ? "#fff" : "#000",
              }}
            >
              {runningCode ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    minHeight: "200px",
                  }}
                >
                  <CircularProgress size={36} sx={{ mr: 0.5 }} />
                </Box>
              ) : result && status ? (
                <Typography
                  variant="body2"
                  sx={{
                    bgcolor: isDarkMode ? "#424242" : "#f9f9f9",
                    whiteSpace: "pre-wrap",
                    wordBreak: "break-word",
                    textAlign: "left",
                    lineHeight: 1.6,
                    p: 1.5,
                    borderRadius: "8px",
                    minHeight: "200px",
                    boxShadow: isDarkMode
                      ? "0px 3px 6px rgba(255, 255, 255, 0.2)"
                      : "0px 3px 6px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <strong>Status:</strong> <br />
                  {status} <br />
                  <strong>Result:</strong> <br />
                  {result}
                </Typography>
              ) : submissionStatus && testcaseResults ? (
                <Box
                  sx={{
                    p: 1.5,
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    bgcolor: isDarkMode ? "#424242" : "#ffffff",
                    color: isDarkMode ? "#ffffff" : "#000000",
                    minHeight: "200px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 1.5,
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mr: 0.5,
                        }}
                      >
                        {(statusIcons as Record<string, React.ReactNode>)[submissionStatus] || (
                          <Error color={isDarkMode ? "inherit" : "error"} />
                        )}
                      </Box>
                      <Typography variant="h6" component="div">
                        {submissionStatus}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider
                    sx={{
                      mb: 1.5,
                      borderColor: isDarkMode ? "#444444" : "#dddddd",
                    }}
                  />
                  <Typography variant="body1" sx={{ fontWeight: 500, mb: 1 }}>
                    Testcase Results:
                  </Typography>
                  <Box
                    sx={{
                      p: 1.5,
                      bgcolor: isDarkMode ? "#333333" : "#ffffff",
                      color: isDarkMode ? "#ffffff" : "#000000",
                      borderRadius: 2,
                      boxShadow: 1,
                      whiteSpace: "pre-wrap",
                      border: `1px solid ${isDarkMode ? "#444444" : "#dddddd"}`,
                      display: "flex",
                      flexDirection: "column",
                      overflow: "auto",
                      maxHeight: "30vh",
                    }}
                  >
                    {testcaseResults.map((result, index) => (
                      <Typography key={index} variant="body2" sx={{ mb: 0.5 }}>
                        Testcase {index + 1}: {result}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              ) : (
                <Typography
                  variant="body2"
                  sx={{
                    bgcolor: isDarkMode ? "#424242" : "#f9f9f9",
                    whiteSpace: "pre-wrap",
                    wordBreak: "break-word",
                    textAlign: "left",
                    lineHeight: 1.6,
                    p: 1.5,
                    borderRadius: "8px",
                    boxShadow: isDarkMode
                      ? "0px 3px 6px rgba(255, 255, 255, 0.2)"
                      : "0px 3px 6px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  Run your code to see the result
                </Typography>
              )}
            </Box>
          </Box>

        </Box>
      </Box>
    </Paper>

  );
};

export default CodeEditor;
