// navigationConfig.tsx
import { FaUserCircle, FaUsers, FaTools, FaEye, FaFileCode } from "react-icons/fa";
import { IoIosCreate } from "react-icons/io";
import { MdDescription } from "react-icons/md";
import { useUser } from '../../contextApi/UserContext';
import { UserRole } from "../../utils/types";

type NavigationItem = {
  kind?: "header" | "divider";
  segment?: string;
  title: string;
  icon?: JSX.Element;
  children?: NavigationItem[];
};

type Navigation = NavigationItem[];

const NAVIGATION: Navigation = [
  { kind: "header", title: "Dashboard" },
  { segment: "profile", title: "My Profile", icon: <FaUserCircle /> },
  { kind: "divider", title: "" },
  { kind: "header", title: "Admin" },
  {
    segment: "users",
    title: "Users Management",
    icon: <FaUsers />,
    children: [
      { segment: "", title: "View All", icon: <FaEye /> },
      { segment: "create", title: "Create", icon: <IoIosCreate /> },
    ]
  },
  {
    segment: "clients",
    title: "Clients Management",
    icon: <FaTools />,
    children: [
      { segment: "", title: "View All", icon: <FaEye /> },
      { segment: "create", title: "Create", icon: <IoIosCreate /> },
    ],
  },
  { kind: "header", title: "Content" },
  {
    segment: "coding-problems",
    title: "Coding Problems",
    icon: <FaFileCode />,
    children: [
      { segment: "", title: "View All", icon: <FaEye /> },
      { segment: "create", title: "Create", icon: <IoIosCreate /> },
    ],
  },
  {
    segment: "mcq-problems",
    title: "MCQ Problems",
    icon: <MdDescription />,
    children: [
      { segment: "", title: "View All", icon: <FaEye /> },
      { segment: "create", title: "Create", icon: <IoIosCreate /> },
    ],
  },
  { kind: "header", title: "Assessments" },
  {
    segment: "assessments",
    title: "Assessment",
    icon: <MdDescription />,
    children: [
      { segment: "", title: "View All", icon: <FaEye /> },
      { segment: "create", title: "Create", icon: <IoIosCreate /> },
    ],
  },
  { kind: "header", title: "Plans" },
  {
    segment: "plans",
    title: "Plans",
    icon: <MdDescription />,
    children: [
      { segment: "", title: "View All", icon: <FaEye /> },
      { segment: "create", title: "Create", icon: <IoIosCreate /> },
    ],
  }
];

// Function to get role-based navigation items
export const getNavigationItems = (role: UserRole): Navigation => {
  if (role === 'admin') {
    // Admin sees all items
    return NAVIGATION;
  } else if (role === 'student') {
    // Student sees only "My Profile"
    return [
      { kind: "header", title: "Dashboard" },
      { segment: "profile", title: "My Profile", icon: <FaUserCircle /> },
    ];

  }
  else if (role === 'client') {
    // Client sees only "My Profile"
    return [
      { kind: "header", title: "Dashboard" },
      { segment: "profile", title: "My Profile", icon: <FaUserCircle /> },
      { kind: "divider", title: "" },
      {
        segment: "subscription",
        title: "Subscription",
        icon: <FaTools />,
        children: [
          { segment: "details", title: "Details", icon: <FaEye /> },
          { segment: "users", title: "User Management", icon: <IoIosCreate /> },
          { segment: "assessments", title: "Assessments", icon: <IoIosCreate /> },
        ],
      }

    ];
  } else if (role === 'author') {
    // Author will see specific items (add as needed)
    return [
      { kind: "header", title: "Dashboard" },
      { segment: "profile", title: "My Profile", icon: <FaUserCircle /> },
      { kind: "header", title: "Content" },
      {
        segment: "coding-problems",
        title: "Coding Problems",
        icon: <FaFileCode />,
        children: [
          { segment: "", title: "View All", icon: <FaEye /> },
          { segment: "create", title: "Create", icon: <IoIosCreate /> },
        ],
      },
      {
        segment: "mcq-problems",
        title: "MCQ Problems",
        icon: <MdDescription />,
        children: [
          { segment: "", title: "View All", icon: <FaEye /> },
          { segment: "create", title: "Create", icon: <IoIosCreate /> },
        ],
      },

    ];
  }

  return []; // Default empty if no role matches
};
