import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Grid,
  Box,
  TextField,
  CircularProgress,
  Container,
  SelectChangeEvent,
} from "@mui/material";
import RichTextField from "../../../components/RichTextField";
import TestcaseSection from "../../../components/TestcaseSection";
import { Testcase } from "../../../constants/ProblemUtils";
import { ProblemFormData, initialData } from "../../../constants/ProblemUtils";
import { difficultyLevels } from "../../../constants/difficultyLevels";
import { tagOptions } from "../../../constants/tagOptions";
import { generateSlug } from "../../../utils/helperFunctions";
import { apiService } from "../../../services/Service";
import CustomSnackbar from "../../../components/CustomSnackbar";
import { SnackbarMessage } from "../../../customHooks/useSnackbar";
import { handleAuthError } from "../../../utils/authUtils";
import { ProblemStatus } from "../../../utils/types";

const CreateProblem: React.FC = () => {
  const [formData, setFormData] = useState<ProblemFormData>(initialData);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessageInfo, setSnackbarMessageInfo] = useState<SnackbarMessage | null>(null);

  const handleOpenSnackbar = () => {
    setSnackbarMessageInfo({
      type: 'success', // or 'error'
      message: 'This is a success message!'
    });
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    setSnackbarMessageInfo(null); // Clear the message after closing
  };

  useEffect(() => {
    const slug = generateSlug(formData.title);
    setFormData((prevData) => ({ ...prevData, slug }));
  }, [formData.title]);

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleRichTextChange =
    (
      field: keyof Omit<ProblemFormData, "publicTestcases" | "privateTestcases">
    ) =>
    (content: string) => {
      setFormData((prevData) => ({ ...prevData, [field]: content }));
    };

  const handleTestcaseChange = (
    type: "public" | "private",
    index: number,
    field: keyof Testcase,
    value: string
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [`${type}Testcases`]: prevData[`${type}Testcases`].map((testcase, i) =>
        i === index ? { ...testcase, [field]: value } : testcase
      ),
    }));
  };

  const handleTagChange = (e: SelectChangeEvent<string[]>) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      tags: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const handleAddTestcase = (type: "public" | "private") => {
    setFormData((prevData) => ({
      ...prevData,
      [`${type}Testcases`]: [
        ...prevData[`${type}Testcases`],
        { input: "", output: "", explanation: "" },
      ],
    }));
  };

  const handleRemoveTestcase = (type: "public" | "private", index: number) => {
    setFormData((prevData) => ({
      ...prevData,
      [`${type}Testcases`]: prevData[`${type}Testcases`].filter(
        (_, i) => i !== index
      ),
    }));
  };

  const handleSubmit = (problemStatus: ProblemStatus) => {
    setLoading(true);
    apiService.post("/author/create", { ...formData, problemStatus }).then((response) => {
      setLoading(false);
      setSnackbarMessageInfo({
        type: 'success', // or 'error'
        message: 'Problem Saved successfully!'
      });
      setSnackbarOpen(true);
      setLoading(false);
    }).catch((error: any) => {
      setLoading(false);
      setSnackbarMessageInfo({
        type: 'error', // or 'error'
        message: error.response.data.error
      });
      setSnackbarOpen(true);
      setLoading(false);
      const errorResponse = error.response?.data;

      if (errorResponse) {
        handleAuthError({ error: errorResponse.error }, window.location.href);
      }
    });
  };

  return (
    <Container >
      <Box
        sx={{
          padding: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: (theme) => theme.shape.borderRadius,
          boxShadow: (theme) => `0 4px 8px ${theme.palette.action.disabled}`,
          marginBottom: 2,
          marginTop: 2,
          marginLeft: "auto",
          marginRight: "auto",
          width: "100%",
          boxSizing: "border-box",
        }}
      >

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Title"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Slug"
              name="slug"
              value={formData.slug}
              onChange={handleInputChange}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Difficulty Level</InputLabel>
              <Select
                name="difficultyLevel"
                value={formData.difficultyLevel}
                onChange={handleInputChange}
                label="Difficulty Level"
              >
                {difficultyLevels.map((level) => (
                  <MenuItem key={level} value={level}>
                    {level}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Tags</InputLabel>
              <Select
                name="tags"
                multiple
                value={formData.tags}
                onChange={handleTagChange}
                renderValue={(selected) => (selected as string[]).join(", ")}
                label="Tags"
              >
                {tagOptions.map((tag) => (
                  <MenuItem key={tag} value={tag}>
                    {tag}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <RichTextField
              label="Description"
              value={formData.description}
              onChange={handleRichTextChange("description")}
            />
          </Grid>
          <Grid item xs={12}>
            <RichTextField
              label="Input Format"
              value={formData.inputFormat}
              onChange={handleRichTextChange("inputFormat")}
            />
          </Grid>
          <Grid item xs={12}>
            <RichTextField
              label="Output Format"
              value={formData.outputFormat}
              onChange={handleRichTextChange("outputFormat")}
            />
          </Grid>
          <Grid item xs={12}>
            <RichTextField
              label="Constraints"
              value={formData.constraints}
              onChange={handleRichTextChange("constraints")}
            />
          </Grid>

          <Grid item xs={12}>
            <TestcaseSection
              title="Public Testcases"
              testcases={formData.publicTestcases as Testcase[]}
              onAdd={() => handleAddTestcase("public")}
              onRemove={(index) => handleRemoveTestcase("public", index)}
              onChange={(index, field, value) =>
                handleTestcaseChange("public", index, field, value)
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TestcaseSection
              title="Private Testcases"
              testcases={formData.privateTestcases as Testcase[]}
              onAdd={() => handleAddTestcase("private")}
              onRemove={(index) => handleRemoveTestcase("private", index)}
              onChange={(index, field, value) =>
                handleTestcaseChange("private", index, field, value)
              }
            />
          </Grid>
        </Grid>

        {!loading ? (
          <Box sx={{ marginTop: 4 }}>
            <Button variant="contained" color="secondary" onClick={()=>handleSubmit(ProblemStatus.DRAFT)}>
              Save As Draft
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={()=>handleSubmit(ProblemStatus.UNDER_REVIEW)}
              sx={{ ml: 2 }}
            >
              Submit For Review
            </Button>
          </Box>
        ) : (
          <CircularProgress />
        )}
      </Box>
      <CustomSnackbar
        open={snackbarOpen}
        messageInfo={snackbarMessageInfo}
        handleClose={handleCloseSnackbar}
      />
    </Container>
  );
};

export default CreateProblem;
