import React from "react";
import { apiService } from "../../services/Service";
import {
    CircularProgress,
    Typography,
    Box,
    Avatar,
    Paper,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Chip,
    Grid,
    Divider,
    Tooltip,
    IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface ClientInfo {
    fullname: string;
    email: string;
    picture: string;
    Client: {
        numberOfUsers: number;
        numberOfTestsPerUser: number;
        validity: string;
        createdAt: string;
        updatedAt: string;
    };
}

const ClientDetails: React.FC = () => {
    const [clientInfo, setClientInfo] = React.useState<ClientInfo | null>(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        const fetchClientInfo = async () => {
            try {
                const response: any = await apiService.get("/user/profile");
                setClientInfo(response.user as ClientInfo);
            } catch (err) {
                console.error("Failed to fetch client information", err);
            } finally {
                setLoading(false);
            }
        };
        fetchClientInfo();
    }, []);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (!clientInfo) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <Typography variant="h6">Failed to load client information</Typography>
            </Box>
        );
    }

    return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" p={2}>
            <Paper elevation={4} sx={{ p: 3, width: "100%", maxWidth: 600, borderRadius: 2 }}>
                <Box display="flex" alignItems="center" flexDirection="column" mb={2}>
                    <Avatar src={clientInfo.picture} alt={clientInfo.fullname} sx={{ width: 100, height: 100, mb: 1 }} />
                    <Typography variant="h5" fontWeight="bold">
                        {clientInfo.fullname}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" mb={2}>
                        {clientInfo.email}
                    </Typography>
                    <Divider sx={{ width: "100%", mb: 2 }} />
                </Box>

                <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6" color="primary" fontWeight="medium">
                            Account Details
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box display="flex" alignItems="center">
                                    <Typography variant="body1" fontWeight="bold" mr={1}>
                                        Number of Users:
                                    </Typography>
                                    <Chip label={clientInfo.Client.numberOfUsers} color="primary" />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" alignItems="center">
                                    <Typography variant="body1" fontWeight="bold" mr={1}>
                                        Tests per User:
                                    </Typography>
                                    <Chip label={clientInfo.Client.numberOfTestsPerUser} color="secondary" />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" alignItems="center">
                                    <Typography variant="body1" fontWeight="bold" mr={1}>
                                        Account Validity:
                                    </Typography>
                                    <Chip
                                        label={new Date(clientInfo.Client.validity).toLocaleDateString()}
                                        color="success"
                                        icon={<InfoOutlinedIcon />}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" alignItems="center">
                                    <Typography variant="body1" fontWeight="bold" mr={1}>
                                        Created At:
                                    </Typography>
                                    <Chip
                                        label={new Date(clientInfo.Client.createdAt).toLocaleDateString()}
                                        variant="outlined"
                                        color="default"
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" alignItems="center">
                                    <Typography variant="body1" fontWeight="bold" mr={1}>
                                        Updated At:
                                    </Typography>
                                    <Chip
                                        label={new Date(clientInfo.Client.updatedAt).toLocaleDateString()}
                                        variant="outlined"
                                        color="default"
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Paper>
        </Box>
    );
};

export default ClientDetails;
