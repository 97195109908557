import React from "react";
import { Box, Typography, IconButton, Button, TextField } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import RichTextField from "./RichTextField";
import { Testcase } from "../constants/ProblemUtils";

interface TestcaseSectionProps {
  title: string;
  testcases: Testcase[];
  onAdd: () => void;
  onRemove: (index: number) => void;
  onChange: (index: number, field: keyof Testcase, value: string) => void;
}

const TestcaseSection: React.FC<TestcaseSectionProps> = ({
  title,
  testcases,
  onAdd,
  onRemove,
  onChange,
}) => {
  return (
    <Box sx={{ marginTop: 4 }}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      {testcases.map((testcase, index) => (
        <Box
          key={index}
          sx={{
            marginBottom: 2,
            border: "1px solid #ddd",
            padding: 2,
            borderRadius: 1,
          }}
        >
          <Typography variant="subtitle1" gutterBottom>
            Testcase {index + 1}
          </Typography>
          <div>
            <Typography variant="body2" color="textSecondary">
              Input
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={testcase.input}
              multiline
              onChange={(e) =>
                onChange(index, "input", e.target.value)
              }
              sx={{ marginBottom: 2 }}
            />
          </div>
          <div>
            <Typography variant="body2" color="textSecondary">
              Output
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={testcase.output}
              multiline
              onChange={(e) =>
                onChange(index, "output", e.target.value)
              }
              sx={{ marginBottom: 2 }}
            />
          </div>
          <div>
            <RichTextField
              label="Explanation"
              value={testcase.explanation || 'Self Explanatory'}
              onChange={(content) => onChange(index, "explanation", content)}
            />
          </div>
          <IconButton
            color="error"
            onClick={() => onRemove(index)}
            sx={{ marginTop: 1 }}
          >
            <RemoveIcon />
          </IconButton>
        </Box>
      ))}
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={onAdd}
      >
        Add {title.split(" ")[0]} Testcase
      </Button>
    </Box>
  );
};

export default TestcaseSection;
