import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardActions,
  TextField,
  Grid,
  Typography,
  Container,
  CircularProgress,
  Box,
  Snackbar,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Save, Clear } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { apiService } from "../../../services/Service"; // Ensure this path is correct
import { handleAuthError } from "../../../utils/authUtils"; // Ensure this path is correct

interface ClientFormData {
  name: string;
  email: string;
  isApproved?: boolean;
  role?: "Client";
  numberOfTestsPerUser: "Unlimited" | number;
  numberOfUsers: number;
  validity: Date;
}

const initialClientData: ClientFormData = {
  name: "",
  email: "",
  isApproved: false,
  role: "Client",
  numberOfTestsPerUser: "Unlimited",
  numberOfUsers: 0,
  validity: new Date(), // Default to current date
};

const CreateClient: React.FC = () => {
  const [formData, setFormData] = useState<ClientFormData>(initialClientData);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = e.target;
    const checked = (e.target as HTMLInputElement).checked;

    if (name === "numberOfTestsPerUser") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value === "Unlimited" ? "Unlimited" : Number(value),
      }));
    } else if (name === "isApproved") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: type === "date" ? new Date(value) : value,
      }));
    }
  };

  const handleUnlimitedChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = e.target.checked;
    setFormData((prevData) => ({
      ...prevData,
      numberOfTestsPerUser: isChecked ? "Unlimited" : 0, // Reset to 0 if unchecked
    }));
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage("");
  };

  const handleSubmit = () => {
    setLoading(true);
    apiService
      .post("/admin/client", formData)
      .then(() => {
        setLoading(false);
        setSnackbarMessage("Client created successfully!");
        setSnackbarOpen(true);
        setFormData(initialClientData); // Reset form after successful submission
      })
      .catch((error: any) => {
        setLoading(false);
        setSnackbarMessage(
          error.response?.data.error || "Failed to create client"
        );
        setSnackbarOpen(true);
        handleAuthError(
          { error: error.response?.data.error },
          window.location.href
        );
      });
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Card sx={{ boxShadow: 3, borderRadius: 2, padding: 2 }}>
        <CardContent>
          <Typography variant="h5" component="div" gutterBottom align="center">
            Create New Client
          </Typography>
          <Box component="form" noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Name"
                  variant="outlined"
                  fullWidth
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  type="email"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.isApproved || false}
                      onChange={handleInputChange}
                      name="isApproved"
                      color="primary"
                    />
                  }
                  label="Approved"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.numberOfTestsPerUser === "Unlimited"}
                      onChange={handleUnlimitedChange}
                      name="unlimitedTests"
                      color="primary"
                    />
                  }
                  label="Unlimited Tests"
                />
              </Grid>
              {formData.numberOfTestsPerUser !== "Unlimited" && (
                <Grid item xs={12}>
                  <TextField
                    label="Number of Tests Per User"
                    variant="outlined"
                    fullWidth
                    name="numberOfTestsPerUser"
                    type="number"
                    value={
                      typeof formData.numberOfTestsPerUser === "number"
                        ? formData.numberOfTestsPerUser
                        : ""
                    }
                    onChange={handleInputChange}
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  label="Number of Users"
                  variant="outlined"
                  fullWidth
                  name="numberOfUsers"
                  type="number"
                  value={formData.numberOfUsers}
                  onChange={handleInputChange}
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Validity"
                  variant="outlined"
                  fullWidth
                  name="validity"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  value={formData.validity.toISOString().substring(0, 10)}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <CardActions sx={{ justifyContent: "center", mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            startIcon={loading ? <CircularProgress size={20} /> : <Save />}
            disabled={loading}
          >
            {loading ? "Saving..." : "Save"}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setFormData(initialClientData)}
            startIcon={<Clear />}
            disabled={loading}
          >
            Clear
          </Button>
        </CardActions>
      </Card>

      {/* Snackbar for feedback messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Container>
  );
};

export default CreateClient;
