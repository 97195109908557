// src/themes/lightTheme.ts
import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#0ea275', // Main primary color
      light: '#3cd2a0',
      dark: '#007a5f',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#f6b80a', // Main secondary color
      light: '#f8d774',
      dark: '#c68c00',
      contrastText: '#000000',
    },
    background: {
      default: '#f4f4f4', // Light mode background
      paper: '#ffffff',   // Paper background
    },
    text: {
      primary: '#333333', // Primary text color
      secondary: '#666666', // Secondary text color
    },
    error: {
      main: '#d32f2f',
      light: '#f6685e',
      dark: '#aa2e25',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#ed6c02',
      light: '#ff9800',
      dark: '#e65100',
      contrastText: '#ffffff',
    },
    info: {
      main: '#0288d1',
      light: '#03a9f4',
      dark: '#01579b',
      contrastText: '#ffffff',
    },
    success: {
      main: '#2e7d32',
      light: '#4caf50',
      dark: '#1b5e20',
      contrastText: '#ffffff',
    },
  },
  typography: {
    fontFamily: '"Poppins", sans-serif',
  },
});

export default lightTheme;
