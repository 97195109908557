import React from 'react';
import { Container, Typography, Button, Box, Card, CardContent, CardActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTheme } from '@mui/material/styles';

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme(); // Access theme colors

  const handleGoHome = () => {
    navigate('/'); // Change this to your homepage route
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        padding: 2,
      }}
    >
      <Card
        sx={{
          width: '100%',
          maxWidth: 500,
          textAlign: 'center',
          borderRadius: 2,
          boxShadow: theme.shadows[5],
          padding: 3,
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <ErrorOutlineIcon
              sx={{
                fontSize: 100,
                color: theme.palette.error.main,
                mb: 2,
              }}
            />
          </Box>

          <Typography
            variant="h4"
            component="h1"
            sx={{
              fontWeight: 600,
              color: theme.palette.text.primary,
              mb: 1,
            }}
          >
            404: Page Not Found
          </Typography>

          <Typography
            variant="body1"
            sx={{
              color: theme.palette.text.secondary,
              mb: 3,
            }}
          >
            The page you are looking for doesn't exist or was moved.
          </Typography>
        </CardContent>

        <CardActions sx={{ justifyContent: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleGoHome}
            sx={{
              textTransform: 'none',
              padding: '10px 24px',
              fontSize: '16px',
              boxShadow: theme.shadows[2],
            }}
          >
            Go to Homepage
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
};

export default NotFoundPage;
