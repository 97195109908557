import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  CardActions,
  TextField,
  Grid,
  Typography,
  Container,
  CircularProgress,
  Box,
  Snackbar,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Save, Clear } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { useParams, useNavigate } from "react-router-dom";
import { apiService } from "../../../services/Service"; // Ensure this path is correct
import { handleAuthError } from "../../../utils/authUtils"; // Ensure this path is correct

// Ensure you have this type defined in the correct file
interface ClientFormData {
  name: string;
  email: string;
  isApproved: boolean;
  role: string;
  numberOfTestsPerUser: string | number;
  numberOfUsers: number;
  validity: Date;
}

const initialClientData: ClientFormData = {
  name: "",
  email: "",
  isApproved: false,
  role: "Client",
  numberOfTestsPerUser: "Unlimited",
  numberOfUsers: 0,
  validity: new Date(),
};

const UpdateClient: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<ClientFormData>(initialClientData);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  useEffect(() => {
    if (id) {
      setLoading(true);
      apiService
        .get<{
          name: string;
          email: string;
          isApproved: boolean;
          role: string;
          numberOfTestsPerUser: string | number;
          numberOfUsers: number;
          validity: string; // Use string here for API response
        }>(`/admin/client/${id}`)
        .then((response) => {
          const { data }:any = response;
          if (data) {
            setFormData({
              name: data.name,
              email: data.email,
              isApproved: data.isApproved,
              role: data.role,
              numberOfTestsPerUser: data.numberOfTestsPerUser,
              numberOfUsers: data.numberOfUsers,
              validity: new Date(data.validity), // Convert string to Date object
            });
          } else {
            setSnackbarMessage("Failed to fetch client data");
            setSnackbarOpen(true);
          }
        })
        .catch((error: any) => {
          setSnackbarMessage(
            error.response?.data.error || "Failed to fetch client data"
          );
          setSnackbarOpen(true);
          handleAuthError(
            { error: error.response?.data.error },
            window.location.href
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = e.target;
    const checked = (e.target as HTMLInputElement).checked;
  
    if (type === "checkbox") {
      setFormData(prevData => ({
        ...prevData,
        [name]: checked
      }));
    } else if (type === "number") {
      // Handle number type inputs
      setFormData(prevData => ({
        ...prevData,
        [name]: value === "" ? "" : Number(value) // Convert empty string to number
      }));
    } else if (name === "validity") {
      setFormData(prevData => ({
        ...prevData,
        [name]: new Date(value) // Convert the value to a Date object
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
  };
  
  const handleSubmit = () => {
    setLoading(true);
    if (id) {
      apiService
        .put(`/admin/client/${id}`, formData)
        .then(() => {
          setSnackbarMessage("Client details updated successfully!");
        })
        .catch((error: any) => {
          setSnackbarMessage(
            error.response?.data.error || "Failed to update client details"
          );
          handleAuthError(
            { error: error.response?.data.error },
            window.location.href
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Card sx={{ boxShadow: 3, borderRadius: 2, padding: 2 }}>
        <CardContent>
          <Typography variant="h5" component="div" gutterBottom align="center">
            Update Client
          </Typography>
          {loading ? (
            <CircularProgress />
          ) : (
            <Box component="form" noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Name"
                    variant="outlined"
                    fullWidth
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    type="email"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.isApproved}
                        onChange={handleInputChange}
                        name="isApproved"
                        color="primary"
                      />
                    }
                    label="Approved"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.numberOfTestsPerUser === "Unlimited"}
                        onChange={(e) => {
                          const newValue = e.target.checked ? "Unlimited" : "0";
                          const syntheticEvent = {
                            target: {
                              name: "numberOfTestsPerUser",
                              value: newValue,
                            },
                          } as React.ChangeEvent<HTMLInputElement>;
                          handleInputChange(syntheticEvent);
                        }}
                        name="unlimitedTests"
                        color="primary"
                      />
                    }
                    label="Unlimited Tests"
                  />
                </Grid>
                {formData.numberOfTestsPerUser !== "Unlimited" && (
                  <Grid item xs={12}>
                    <TextField
                      label="Number of Tests Per User"
                      variant="outlined"
                      fullWidth
                      name="numberOfTestsPerUser"
                      type="number"
                      value={typeof formData.numberOfTestsPerUser === "number" ? formData.numberOfTestsPerUser : ""}
                      onChange={handleInputChange}
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    label="Number of Users"
                    variant="outlined"
                    fullWidth
                    name="numberOfUsers"
                    type="number"
                    value={formData.numberOfUsers}
                    onChange={handleInputChange}
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Validity"
                    variant="outlined"
                    fullWidth
                    name="validity"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={formData.validity.toISOString().split('T')[0]} // Format date as YYYY-MM-DD
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </CardContent>
        <CardActions sx={{ justifyContent: "center", mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            startIcon={loading ? <CircularProgress size={20} /> : <Save />}
            disabled={loading}
          >
            {loading ? "Saving..." : "Save"}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate("/admin/manage-clients")}
            startIcon={<Clear />}
            disabled={loading}
          >
            Cancel
          </Button>
        </CardActions>
      </Card>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setSnackbarOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Container>
  );
};

export default UpdateClient;
