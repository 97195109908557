// src/pages/ProfileDashboard.tsx
import React, { useEffect } from "react";
import { Container } from "@mui/material";
import ProfileHeader from "../components/ProfileHeader";
import { useUser } from "../contextApi/UserContext";

const ProfileDashboard: React.FC = () => {
  const { user } = useUser();

  const handleLogout = () => {
    localStorage.removeItem("jwt_token");
    window.location.href = "/login";
  };

  useEffect(() => {
    if (!user) {
      localStorage.removeItem("jwt_token");
      window.location.href = "/login";
    }
  }, [user]);

  return (
    <Container>
      <ProfileHeader
        fullname={user?.fullname || ""}
        email={user?.email || ""}
        picture={user?.picture}
        role={user?.role || ""}
        onLogout={handleLogout}
      />
    </Container>
  );
};

export default ProfileDashboard;
