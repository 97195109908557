import React, { useState } from "react";
import {
    Box,
    TextField,
    Button,
    Typography,
    Paper,
    List,
    ListItem,
    ListItemText,
    Divider,
    Grid,
} from "@mui/material";
import { UploadFile } from "@mui/icons-material";
import Papa from "papaparse";

const UserManagement: React.FC = () => {
    const [emails, setEmails] = useState<string[]>([]);
    const [manualEmails, setManualEmails] = useState<string>("");

    // Handle manual input for space-separated emails
    const handleManualEmailInput = () => {
        const emailList = manualEmails
            .split(" ")
            .map((email) => email.trim())
            .filter((email) => /\S+@\S+\.\S+/.test(email)); // Simple email validation
        setEmails((prevEmails) => [...prevEmails, ...emailList]);
        setManualEmails(""); // Reset input
    };

    // Handle file upload and parse CSV
    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            Papa.parse(file, {
                header: true,
                complete: (results) => {
                    const parsedEmails = results.data
                        .map((row: any) => row.Email || row.email)
                        .filter((email: string) => /\S+@\S+\.\S+/.test(email));
                    setEmails((prevEmails) => [...prevEmails, ...parsedEmails]);
                },
                error: (error) => {
                    console.error("Error parsing CSV file:", error);
                },
            });
        }
    };

    return (
        <Box p={3} maxWidth={900} m="auto">
            <Typography variant="h4" align="center" mb={3}>
                User Management
            </Typography>
            <Grid container spacing={3}>
                {/* Left side: Uploaded emails list */}
                <Grid item xs={12} md={6}>
                    <Paper
                        elevation={3}
                        sx={{
                            p: 3,
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            Uploaded Emails ({emails.length})
                        </Typography>
                        {emails.length > 0 ? (
                            <List sx={{ maxHeight: 350, overflow: "auto", flexGrow: 1 }}>
                                {emails.map((email, index) => (
                                    <React.Fragment key={index}>
                                        <ListItem>
                                            <Grid container>
                                                <Grid item xs={2}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        {index + 1}.
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <ListItemText primary={email} />
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                        <Divider />
                                    </React.Fragment>
                                ))}
                            </List>
                        ) : (
                            <Typography>No emails uploaded yet.</Typography>
                        )}
                    </Paper>
                </Grid>

                {/* Right side: Input and file upload */}
                <Grid item xs={12} md={6}>
                    <Paper
                        elevation={3}
                        sx={{
                            p: 3,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            height: "100%",
                        }}
                    >
                        <Box mb={2}>
                            <Typography variant="h6" gutterBottom>
                                Enter Emails Manually
                            </Typography>
                            <TextField
                                label="Emails (space-separated)"
                                variant="outlined"
                                fullWidth
                                value={manualEmails}
                                onChange={(e) => setManualEmails(e.target.value)}
                                sx={{ mb: 2 }}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleManualEmailInput}
                                fullWidth
                            >
                                Add Emails
                            </Button>
                        </Box>
                        <Divider sx={{ my: 2 }} />
                        <Box>
                            <Typography variant="h6" gutterBottom>
                                Upload CSV File
                            </Typography>
                            <Button
                                variant="outlined"
                                component="label"
                                startIcon={<UploadFile />}
                                fullWidth
                                sx={{ mt: 1 }}
                            >
                                Upload CSV
                                <input type="file" hidden accept=".csv" onChange={handleFileUpload} />
                            </Button>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default UserManagement;
