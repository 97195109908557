import React from "react";
import { FormControl, InputLabel, Select, MenuItem, useTheme } from "@mui/material";
import { SelectChangeEvent } from "@mui/material";
import { languages } from "../../constants/EditorConstants";

interface Language {
  label: string;
  id: number;
  value: string;
}

interface LanguageSelectorProps {
  language: Language;
  onChange: (event: SelectChangeEvent<string>) => void;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ language, onChange }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  return (
    <FormControl fullWidth>
      <InputLabel
        sx={{
          color: isDarkMode ? "#ffffff" : "#000000",
        }}
      >
        Language
      </InputLabel>
      <Select
        value={language.value}
        onChange={onChange}
        sx={{
          borderRadius: "10px",
          backgroundColor: isDarkMode ? "#424242" : "#ffffff",
          color: isDarkMode ? "#ffffff" : "#000000",
          '& .MuiSelect-icon': {
            color: isDarkMode ? "#ffffff" : "#000000",
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: isDarkMode ? "#ffffff" : "#000000",
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: isDarkMode ? "#ffffff" : "#000000",
          },
        }}
      >
        {languages.map((lang) => (
          <MenuItem key={lang.value} value={lang.value}>
            {lang.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LanguageSelector;
