import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Chip,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Pagination,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import { apiService } from "../../services/Service";
import { AxiosError } from "axios";
import { ErrorResponse } from "../../utils/types";
import { handleAuthError } from "../../utils/authUtils";
import { tagOptions } from "../../constants/tagOptions";
import useDebounce from "../../customHooks/useDebounce";

interface AddCodingProblemsProps {
  addQuestion: (questionId: string, type: "coding") => void;
  selectedQuestions?: string[]; // Optional prop for pre-selected questions
}
const AddCodingProblems: React.FC<AddCodingProblemsProps> = ({ addQuestion, selectedQuestions = [] }) => {
  const [codingProblems, setCodingProblems] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [search, setSearch] = useState<string>("");
  const [difficulty, setDifficulty] = useState<string>("");
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [selectedProblems, setSelectedProblems] = useState<string[]>(selectedQuestions); // Initialize with selectedQuestions
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const debouncedSearch = useDebounce(search, 500);
  useEffect(() => {
    // Sync selectedProblems with selectedQuestions when prop changes
    setSelectedProblems(selectedQuestions);
  }, [selectedQuestions]);

  useEffect(() => {
    console.log("selectedProblems:", selectedProblems);
    const fetchCodingProblems = async () => {
      setLoading(true);
      try {
        const params = new URLSearchParams({
          search: debouncedSearch,
          difficulty,
          tags: selectedTags.join(","),
          page: page.toString(),
          limit: "10",
        }).toString();

        const response = await apiService.get<{
          problems: any[];
          totalPages: number;
        }>(`/problem?${params}`);
        setCodingProblems(response.problems);
        setTotalPages(response.totalPages);
      } catch (err) {
        setError("Failed to fetch coding problems");
        const error = err as AxiosError<ErrorResponse>;
        const errorResponse = error.response?.data;

        if (errorResponse) {
          handleAuthError({ error: errorResponse.error }, window.location.href);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchCodingProblems();
  }, [debouncedSearch, difficulty, selectedTags, page]);

  const handleCheckboxChange = (questionId: string) => {
    setSelectedProblems((prev) => {
        const isSelected = prev.includes(questionId);
        const updatedSelection = isSelected
            ? prev.filter((id) => id !== questionId) // Remove if unchecked
            : [...prev, questionId]; // Add if checked

        addQuestion(questionId, 'coding'); // Call parent function to update formData
        return updatedSelection;
    });
};


  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleDifficultyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDifficulty(event.target.value as string);
  };

  const handleTagChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedTags(event.target.value as string[]);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );

  if (error)
    return (
      <Box textAlign="center" color="error.main" mt={4}>
        {error}
      </Box>
    );

  return (
    <Box>
      <Box mb={2} display="flex" gap={2} flexWrap="wrap" alignItems="center">
        <TextField
          variant="outlined"
          value={search}
          onChange={handleSearchChange}
          label="Search"
          placeholder="Search by title"
          sx={{ flex: 1, minWidth: 200 }}
        />
        <FormControl variant="outlined" sx={{ flex: 1, minWidth: 150 }}>
          <InputLabel>Difficulty</InputLabel>
          <Select
            value={difficulty}
            onChange={(e: any) => handleDifficultyChange(e)}
            label="Difficulty"
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="easy">Easy</MenuItem>
            <MenuItem value="medium">Medium</MenuItem>
            <MenuItem value="hard">Hard</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" sx={{ flex: 2, minWidth: 200 }}>
          <InputLabel>Tags</InputLabel>
          <Select
            multiple
            value={selectedTags}
            onChange={(e: any) => handleTagChange(e)}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {(selected as string[]).map((tag) => (
                  <Chip key={tag} label={tag} />
                ))}
              </Box>
            )}
            label="Tags"
          >
            {tagOptions.map((tag) => (
              <MenuItem key={tag} value={tag}>
                {tag}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Grid container spacing={2}>
        {codingProblems?.map((problem) => (
          <Grid item xs={12} sm={6} md={4} key={problem._id}>
            <Card
              sx={{
                height: 200,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <CardContent
                sx={{
                  overflowY: "auto",
                  flex: 1,
                }}
              >
                <Typography variant="h6" gutterBottom>
                  <div dangerouslySetInnerHTML={{ __html: problem.title }} />
                </Typography>
                <Box mb={1}>
                  <Chip
                    label={problem.difficultyLevel}
                    color="primary"
                    size="small"
                    sx={{ mr: 0.5 }}
                  />
                  {problem.tags.map((tag: string) => (
                    <Chip
                      key={tag}
                      label={tag}
                      color="secondary"
                      size="small"
                      sx={{ mr: 0.5 }}
                    />
                  ))}
                </Box>
              </CardContent>
              <CardActions sx={{ justifyContent: "space-between", p: 1 }}>
                <Checkbox
                  checked={selectedProblems.includes(problem._id)}
                  onChange={() => handleCheckboxChange(problem._id)}
                  color="primary"
                  icon={<CheckCircle color="disabled" />}
                  checkedIcon={<CheckCircle color="success" />}
                />
                <Button size="small" color="primary">
                  View
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box mt={2} display="flex" justifyContent="center">
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </Box>
  );
};

export default AddCodingProblems;
