import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { apiService } from "../../../services/Service";
import { AxiosError } from "axios";
import { handleAuthError } from "../../../utils/authUtils";
import {
  Box,
  CircularProgress,
  Typography,
  Drawer,
  Paper,
  IconButton,
  Grid,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { AccessTime, CheckCircleOutline, ErrorOutline, Timer } from "@mui/icons-material";
import RenderQuestions from "./RenderQuestions";
import CodingProblemRender from "./CodingProblemRender";
import McqProblemRenderer from "./McqProblemRenderer";
import { formatTime } from "../../../utils/helperFunctions";

const MemoizedCodingProblemRender = React.memo(CodingProblemRender);
const MemoizedMcqProblemRenderer = React.memo(McqProblemRenderer);
const MemoizedRenderQuestions = React.memo(RenderQuestions);
const AssessmentHomePage = () => {
  const { assessmentslug } = useParams<{ assessmentslug: string }>();
  const [assessmentData, setAssessmentData] = useState<any>(null);
  const [selectedQuestion, setSelectedQuestion] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [timer, setTimer] = useState<number>(0);
  const [timerRunning, setTimerRunning] = useState<boolean>(false);
  const [AssessmentEnded, setAssessmentEnded] = useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const [userMcqResponse, setUserMcqResponse] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);



  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response: any = await apiService.get(`/assessments/questions/${assessmentslug}`);
        const { startTime: starttime, endTime: endtime } = response.data.details;

        // Parse startTime and endTime to milliseconds
        const startTime = new Date(starttime).getTime();
        const endTime = new Date(endtime).getTime();
        const currentTime = Date.now();

        if (currentTime < startTime) {
          setTimer((startTime - currentTime) / 1000); // Time until start
          setTimerRunning(false);
        } else if (currentTime >= startTime && currentTime < endTime) {
          setTimer((endTime - currentTime) / 1000); // Remaining contest time
          setTimerRunning(true);
        } else {
          setTimer(0);
          setTimerRunning(false);
          setAssessmentEnded(true);
        }
        setAssessmentData(response.data);
        setSelectedQuestion(
          response.data.coding.length > 0
            ? response.data.coding[0]
            : response.data.mcq[0]
        );
        if(response.data.details.type === "mcq" || response.data.details.type === "mixed"){
          let mcqs = response.data.details.questions.filter((mcq: any) => mcq.type === "mcq");
          let initialResponses = mcqs.map((mcq: any) => {
            return {
              mcqId: mcq.questionId,
              selectedOption: "",
            };
          })
          if (!userMcqResponse.length) {
            setUserMcqResponse(initialResponses);
            localStorage.setItem("userMcqResponse", JSON.stringify(initialResponses));
          }
        }
        setLoading(false);
      } catch (error:any) {
        if (error instanceof AxiosError) {
          handleAuthError({ error: error.response?.data.error }, window.location.href);
        }
        setErrorMessage(error.response?.data.error||"Failed to fetch assessment data");
        setLoading(false);
      }
    };
    fetchData();
  }, [assessmentslug]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (timerRunning && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timer <= 0) {
      setTimerRunning(false);
    }
    return () => clearInterval(interval);
  }, [timerRunning, timer]);

  useEffect(() => {
    if(timer<0){
      onSubmitAssessment();
    }
    if (timer <= 0) {
      setAssessmentEnded(true);
    } else {
      setAssessmentEnded(false);
    }
  }, [timer]);

  const renderQuestionContent = useMemo(() => {
    if (!selectedQuestion) {
      return <Typography>Select a question to begin.</Typography>;
    }

    if (assessmentData?.coding.includes(selectedQuestion)) {
      return <MemoizedCodingProblemRender slug={selectedQuestion.slug} />;
    }

    if (assessmentData?.mcq.includes(selectedQuestion)) {
      return <MemoizedMcqProblemRenderer selectedQuestion={selectedQuestion} userMcqResponse={userMcqResponse}  />;
    }
  }, [selectedQuestion, assessmentData]);


  useEffect(() => {
     localStorage.setItem("userMcqResponse", JSON.stringify(userMcqResponse));
  }, [userMcqResponse]);
  
  const submitAssessment = async () => {
    const submitData = {
      assessmentId: assessmentData.details._id,
      mcqResponses: JSON.parse(localStorage.getItem("userMcqResponse")||"[]"),
    }

    try { 
      await apiService.post(`/assessments/submit`, submitData);
      setAssessmentEnded(true);
      setErrorMessage("Assessment submitted successfully");
    } catch (error) {
      console.error("Error submitting assessment:", error);
    }
  }

  const clearLocalStorage = () => {
    // Remove a specific key
    localStorage.removeItem("userMcqResponse");
  
    // Iterate through all keys and remove those starting with "mcq"
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith("mcq")) {
        localStorage.removeItem(key);
      }
    });
  };
  
  const onSubmitAssessment = async () => {
    handleClose();
    submitAssessment();
    clearLocalStorage();
  };


  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (errorMessage) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography display={"flex"} justifyContent={"center"} variant="h4" gutterBottom>
         {errorMessage}
        </Typography>
      </Box>
    );
  }
  if (AssessmentEnded) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography display={"flex"} justifyContent={"center"} variant="h4" gutterBottom>
          Assessment has ended
        </Typography>
      </Box>
    );
  }

  return (
    <Box display="flex">
      {/* Left Panel */}
      <Drawer
        anchor="left"
        open={true}
        variant="permanent"
        sx={{
          width: 280,
          "& .MuiDrawer-paper": {
            width: 280,
            boxSizing: "border-box",
          },
        }}
      >
        <Box p={1}>
          <MemoizedRenderQuestions
            assessmentData={assessmentData}
            selectedQuestion={selectedQuestion}
            setSelectedQuestion={setSelectedQuestion}
          />
        </Box>
      </Drawer>

      {/* Right Panel */}
      <Box p={0} flex={2} justifyContent={"space-between"}>
        {/* Timer */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            padding: 1,
            backgroundColor: "background.paper",
          }}
        >
          <Box>
            <Typography variant="h5" sx={{ fontWeight: "bold", color: "primary.main" }}>
              {assessmentData.details.name}
            </Typography>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item display="flex" alignItems="center" gap={1}>
                <AccessTime sx={{ color: "success.main" }} />
                <Typography variant="body2" color="text.secondary">
                  Start: {new Date(assessmentData.details.startTime).toLocaleString()}
                </Typography>
              </Grid>
              <Grid item display="flex" alignItems="center" gap={1}>
                <Typography variant="body2" color="text.secondary">
                  End: {new Date(assessmentData.details.endTime).toLocaleString()}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box display="flex" alignItems="center" gap={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpen}
              disabled={timer <= 0}
            >
              Submit Assessment
            </Button>

            <Chip
              label={timer > 0 ? "Ongoing" : "Completed"}
              color={timer > 0 ? "success" : "error"}
              icon={timer > 0 ? <CheckCircleOutline /> : <ErrorOutline />}
              sx={{ fontWeight: "bold", paddingX: 1 }}
            />

            <Paper
              sx={{
                display: "flex",
                alignItems: "center",
                padding: 1,
                boxShadow: 3,
                borderRadius: 2,
                backgroundColor: "primary.light",
              }}
            >
              <IconButton disabled sx={{ color: "primary.dark" }}>
                <Timer sx={{ fontSize: 30, color: "primary.dark" }} />
              </IconButton>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  color: "text.primary",
                  width: "120px",
                  textAlign: "center",
                  fontFamily: "monospace",
                }}
              >
                {timer > 0 ? `${formatTime(timer)}` : "Time's up!"}
              </Typography>
            </Paper>
          </Box>
        </Box>

        {/* Question Content */}
        <Paper elevation={2} sx={{ p: 1 }}>
          {renderQuestionContent}
        </Paper>
      </Box>

      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Submit Assessment?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to submit the assessment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onSubmitAssessment}>Yes Submit</Button>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AssessmentHomePage;
