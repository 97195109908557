// utils/authUtils.ts

export const handleAuthError = (
  errorResponse: { error: string },
  redirectUrl?: string
) => {
  if (
    errorResponse?.error === "Not authorized, no token provided" ||
    errorResponse?.error === "Not authorized, token verification failed"
  ) {
    // Redirect to login page with a redirect URL
    const loginRedirect = redirectUrl
      ? `/login?redirect=${encodeURIComponent(redirectUrl)}`
      : "/login";
    window.location.href = loginRedirect;
  } else if (errorResponse?.error === "Access denied, admin only") {
    // Redirect to profile page
    window.location.href = "/profile";
  } else {
    // Handle other errors or log them
    console.error("Error:", errorResponse.error);
  }
};
