import React from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const LandingPageContent: React.FC = () => {
  const theme = useTheme(); // Access the theme

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        maxWidth: "100%",
        maxHeight: "100%",
        overflow: "hidden",
        mb: 4,
      }}
    >
      <svg
        viewBox="0 0 800 800"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: "100%",
          height: "auto",
          maxWidth: "100%",
          maxHeight: "100%",
        }}
      >
        <defs>
          <filter id="shadow" x="0" y="0" width="150%" height="150%">
            <feDropShadow
              dx="4"
              dy="4"
              stdDeviation="10"
              floodColor="#000"
              floodOpacity="0.4"
            />
          </filter>
          <radialGradient
            id="iconGradient"
            cx="50%"
            cy="50%"
            r="50%"
            fx="50%"
            fy="50%"
          >
            <stop
              offset="0%"
              style={{ stopColor: theme.palette.primary.light, stopOpacity: 1 }}
            />
            <stop
              offset="100%"
              style={{ stopColor: theme.palette.secondary.main, stopOpacity: 1 }}
            />
          </radialGradient>
        </defs>
        <style>
          {`
            .circle-bg {
              fill: ${theme.palette.background.paper}; // Use theme background paper color
              stroke: ${theme.palette.primary.main}; // Use primary color for strokes
              stroke-width: 4px;
            }
            .skill-icon {
              font-size: 48px;
              fill: url(#iconGradient);
              filter: url(#shadow);
            }
            .skill-text {
              font-family: ${theme.typography.fontFamily};
              font-size: 18px;
              text-anchor: middle;
              fill: ${theme.palette.text.secondary}; // Use secondary text color from theme
              dominant-baseline: middle;
              font-weight: bold;
            }
            .center-text {
              font-family: ${theme.typography.fontFamily};
              font-size: 60px;
              text-anchor: middle;
              fill: ${theme.palette.primary.main}; // Use primary color for the center text
              dominant-baseline: middle;
              font-weight: bold;
              text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
            }
          `}
        </style>

        {/* Central circle */}
        <circle
          cx="400"
          cy="400"
          r="320"
          fill="none"
          stroke={theme.palette.divider} // Use divider color from the theme
          strokeWidth="2"
        />

        {/* Skill Icons and Text */}
        <g className="skill-text">
          {/* Skill 1 */}
          <circle cx="400" cy="100" r="70" className="circle-bg" />
          <text x="400" y="100" className="skill-icon">
            💻
          </text>
          <text x="400" y="190">
            Coding
          </text>
          {/* Skill 2 */}
          <circle cx="620" cy="200" r="70" className="circle-bg" />
          <text x="620" y="200" className="skill-icon">
            📊
          </text>
          <text x="620" y="290">
            Algorithms
          </text>
          {/* Skill 3 */}
          <circle cx="690" cy="400" r="70" className="circle-bg" />
          <text x="690" y="400" className="skill-icon">
            🌐
          </text>
          <text x="690" y="490">
            Data Structures
          </text>
          {/* Skill 4 */}
          <circle cx="620" cy="600" r="70" className="circle-bg" />
          <text x="620" y="600" className="skill-icon">
            📅
          </text>
          <text x="620" y="690">
            MCQs
          </text>
          {/* Skill 5 */}
          <circle cx="400" cy="700" r="70" className="circle-bg" />
          <text x="400" y="700" className="skill-icon">
            🧠
          </text>
          <text x="400" y="790">
            Aptitude
          </text>
          {/* Skill 6 */}
          <circle cx="180" cy="600" r="70" className="circle-bg" />
          <text x="180" y="600" className="skill-icon">
            🧪
          </text>
          <text x="180" y="690">
            Tests
          </text>
          {/* Skill 7 */}
          <circle cx="110" cy="400" r="70" className="circle-bg" />
          <text x="110" y="400" className="skill-icon">
            📚
          </text>
          <text x="110" y="490">
            Exams
          </text>
          {/* Skill 8 */}
          <circle cx="180" cy="200" r="70" className="circle-bg" />
          <text x="180" y="200" className="skill-icon">
            📝
          </text>
          <text x="180" y="290">
            Quizzes
          </text>
        </g>

        {/* Center text */}
        <text x="400" y="400" className="center-text">
          Codechatura
        </text>
      </svg>
    </Box>
  );
};

export default LandingPageContent;
