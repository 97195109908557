import React from 'react';
import { Container, Typography, TextField, Button, Card, CardContent, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useTheme } from '@mui/material/styles';

// Styled Components
const ContactCard = styled(Card)(({ theme }) => ({
  borderRadius: '12px',
  boxShadow: theme.shadows[4],
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: theme.shadows[6],
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.background.paper,
  borderRadius: '50%',
  width: 60,
  height: 60,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

const ContactUs: React.FC = () => {
  const theme = useTheme();

  return (
    <Box sx={{ backgroundColor: theme.palette.background.default }} py={10} id="contact">
      <Container maxWidth="sm">
        <Typography variant="h3" align="center" fontWeight="bold" gutterBottom color={theme.palette.text.primary}>
          Get in Touch
        </Typography>
        <ContactCard>
          <CardContent>
            <IconWrapper>
              <MailOutlineIcon sx={{ fontSize: 30 }} />
            </IconWrapper>
            <Typography variant="h5" align="center" gutterBottom color={theme.palette.text.primary}>
              Request a Quote
            </Typography>
            <form>
              <TextField
                fullWidth
                label="Name"
                variant="outlined"
                margin="normal"
                required
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                margin="normal"
                type="email"
                required
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Message"
                variant="outlined"
                margin="normal"
                multiline
                rows={4}
                required
                sx={{ mb: 2 }}
              />
              <Button
                type="submit"
                variant="contained"
                size="large"
                fullWidth
                sx={{
                  mt: 2,
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.background.paper,
                }}
              >
                Send Request
              </Button>
            </form>
          </CardContent>
        </ContactCard>
      </Container>
    </Box>
  );
};

export default ContactUs;
