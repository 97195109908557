import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Card,
  CardContent,
  Typography,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Alert,
  Autocomplete,
} from "@mui/material";
import * as XLSX from "xlsx";
import { apiService } from "../../../services/Service";
import { AxiosError } from "axios";
import { ErrorResponse } from "../../../utils/types";
import { handleAuthError } from "../../../utils/authUtils";
import { SnackbarMessage } from "../../../customHooks/useSnackbar";
import CustomSnackbar from "../../../components/CustomSnackbar";

interface User {
  id: number;
  email: string;
  role: string;
  clientId?: string;
}

interface Client {
  _id: string;
  fullname?: string;
  email: string;
}

const CreateUser = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const [emailInput, setEmailInput] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [fileError, setFileError] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessageInfo, setSnackbarMessageInfo] = useState<SnackbarMessage | null>(null);
  const [clients, setClients] = useState<Client[]>([]);
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);

  // Validate email
  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email.trim());
  };

  const handleAddUsers = () => {
    if (!selectedClient) {
      setError("Please select a client before adding users.");
      return;
    }
  
    const emails = emailInput.split(/\s+/).filter((email) => email.trim() !== "");
  
    const invalidEmails = emails.filter((email) => !isValidEmail(email));
    if (invalidEmails.length > 0) {
      setError(`Invalid emails found: ${invalidEmails.join(", ")}`);
      return;
    }
  
    const duplicateEmails = emails.filter((email) =>
      users.some((user) => user.email === email.trim())
    );
    if (duplicateEmails.length > 0) {
      setError(`Duplicate emails found: ${duplicateEmails.join(", ")}`);
      return;
    }
  
    const newUsers = emails.map((email, index) => ({
      id: users.length + index + 1,
      email: email.trim(),
      role: "student",
      clientId: selectedClient._id, // Attach clientId
    }));
  
    setUsers((prevUsers) => [...prevUsers, ...newUsers]);
    setEmailInput(""); // Clear the input field
    setError(null); // Clear any previous error
  };
  
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!selectedClient) {
      setFileError("Please select a client before uploading a file.");
      return;
    }
  
    const file = event.target.files?.[0];
    if (!file) return;
  
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target?.result;
      if (data) {
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json<{ email: string }>(sheet);
  
        const emails = jsonData.map((row) => row.email.trim());
        const invalidEmails = emails.filter((email) => !isValidEmail(email));
        const duplicateEmails = emails.filter((email) =>
          users.some((user) => user.email === email.trim())
        );
  
        if (invalidEmails.length > 0) {
          setFileError(`Invalid emails found in file: ${invalidEmails.join(", ")}`);
          return;
        }
  
        if (duplicateEmails.length > 0) {
          setFileError(`Duplicate emails found in file: ${duplicateEmails.join(", ")}`);
          return;
        }
  
        const newUsers = emails.map((email, index) => ({
          id: users.length + index + 1,
          email,
          role: "student",
          clientId: selectedClient._id, // Attach clientId
        }));
  
        setUsers((prevUsers) => [...prevUsers, ...newUsers]);
        setFileError(null); // Clear any previous file error
      }
    };
  
    if (
      file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "text/csv"
    ) {
      reader.readAsBinaryString(file);
    } else {
      setFileError("Invalid file type. Please upload a valid CSV or XLSX file.");
    }
  };
  

  const handleOpenSnackbar = () => {
    setSnackbarMessageInfo({
      type: "success",
      message: "Users created successfully!",
    });
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    setSnackbarMessageInfo(null);
  };

  const handleSubmit = () => {
    if (!selectedClient) {
      setSnackbarMessageInfo({
        type: "error",
        message: "Please select a client.",
      });
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    apiService
      .post("/admin/users", { users, clientId: selectedClient._id })
      .then(() => {
        setLoading(false);
        handleOpenSnackbar();
      })
      .catch((error: any) => {
        setLoading(false);
        setSnackbarMessageInfo({
          type: "error",
          message:
            error.response.data.error ||
            error.response.data.message ||
            "Failed to create users",
        });
        console.log(error);
        setSnackbarOpen(true);
        handleAuthError(
          { error: error.response?.data.error },
          window.location.href
        );
      });
  };

  useEffect(() => {
    apiService
      .get("/admin/client/list")
      .then((response: any) => {
        setClients(response.clients);
      })
      .catch((error: any) => {
        console.log(error);
        handleAuthError(
          { error: error.response?.data.error },
          window.location.href
        );
      });
  }, []);

  return (
    <Box display="flex" gap={4} p={4}>
      {/* Left Section: User List */}
      <Card style={{ flex: 1, maxHeight: "400px", overflowY: "auto" }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Added Users ({users.length})
          </Typography>
          {users.length > 0 ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Role</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell>{user.id}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.role}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography color="textSecondary">No users added yet.</Typography>
          )}
        </CardContent>
      </Card>

      {/* Right Section */}
      <Box flex={1} display="flex" flexDirection="column" gap={3}>
        {/* Client Selection */}
        <Card style={{ padding: "16px" }}>
          <Typography variant="h6" gutterBottom>
            Select Client
          </Typography>
          <Autocomplete
            options={clients}
            getOptionLabel={(option) =>
              `${option.fullname || "Client not logged in"} (${option.email.toLowerCase()})`
            }
            renderInput={(params) => <TextField {...params} label="Search by email or name" />}
            onChange={(event, value) => setSelectedClient(value)}
            isOptionEqualToValue={(option, value) => option._id === value._id}
          />
        </Card>

        {/* File Upload */}
        <Card style={{ padding: "16px" }}>
          <Typography variant="h6" gutterBottom>
            Upload CSV or XLSX File
          </Typography>
          <TextField
            type="file"
            inputProps={{ accept: ".csv, .xlsx" }}
            onChange={handleFileUpload}
            fullWidth
          />
          {fileError && <Alert severity="error" style={{ marginTop: "8px" }}>{fileError}</Alert>}
        </Card>

        {/* Manual Email Input */}
        <Card style={{ padding: "16px" }}>
          <Typography variant="h6" gutterBottom>
            Add Emails Manually
          </Typography>
          <TextField
            placeholder="Enter emails separated by spaces"
            value={emailInput}
            onChange={(e) => setEmailInput(e.target.value)}
            multiline
            rows={4}
            fullWidth
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddUsers}
            style={{ marginTop: "16px" }}
          >
            Add Users
          </Button>
          {error && <Alert severity="error" style={{ marginTop: "8px" }}>{error}</Alert>}
        </Card>

        {/* Submit Button */}
        <Button
          variant="contained"
          color="success"
     
          
          onClick={handleSubmit}
          disabled={loading}
        >
          Submit
        </Button>
      </Box>

      {/* Snackbar */}
      <CustomSnackbar
        open={snackbarOpen}
        messageInfo={snackbarMessageInfo}
        handleClose={handleCloseSnackbar}
      />
    </Box>
  );
};

export default CreateUser;
