// src/themes/darkTheme.ts
import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#0ea275', // Main primary color
      light: '#3cd2a0',
      dark: '#007a5f',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#f6b80a', // Main secondary color
      light: '#f8d774',
      dark: '#c68c00',
      contrastText: '#000000',
    },
    background: {
      default: '#121212', // Dark mode background
      paper: '#1e1e1e',   // Darker paper background
    },
    text: {
      primary: '#ffffff', // Primary text color
      secondary: '#aaaaaa', // Secondary text color
    },
    error: {
      main: '#f44336',
      light: '#e57373',
      dark: '#d32f2f',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#ff9800',
      light: '#ffb74d',
      dark: '#f57c00',
      contrastText: '#ffffff',
    },
    info: {
      main: '#2196f3',
      light: '#64b5f6',
      dark: '#1976d2',
      contrastText: '#ffffff',
    },
    success: {
      main: '#4caf50',
      light: '#81c784',
      dark: '#388e3c',
      contrastText: '#ffffff',
    },
  },
  typography: {
    fontFamily: '"Poppins", sans-serif',
  },
});

export default darkTheme;
