import React from 'react';
import { Container, Grid, Card, CardContent, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { useTheme } from '@mui/material/styles';

const TestimonialCard = styled(Card)(({ theme }) => ({
  borderRadius: '12px',
  boxShadow: theme.shadows[4],
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: theme.shadows[6],
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.background.paper,
  borderRadius: '50%',
  width: 80,
  height: 80,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

const Testimonials: React.FC = () => {
  const theme = useTheme();

  const testimonials = [
    {
      quote: "CodeChatura has completely changed how we assess our students. The automated grading system is a game-changer!",
      name: "John Doe",
      institution: "College ABC",
    },
    {
      quote: "The detailed analytics help us track student progress like never before. Our students are improving faster.",
      name: "Jane Smith",
      institution: "University XYZ",
    },
    {
      quote: "Integrating CodeChatura with our existing LMS was seamless. The support team is always there when we need them.",
      name: "Mike Johnson",
      institution: "EduCorp",
    },
  ];

  return (
    <Box py={10} bgcolor={theme.palette.background.default} id="testimonials">
      <Container>
        <Typography variant="h3" align="center" fontWeight="bold" gutterBottom color={theme.palette.text.primary}>
          What Our Clients Say
        </Typography>
        <Grid container spacing={4} mt={4}>
          {testimonials.map((testimonial, index) => (
            <Grid item xs={12} md={4} key={index}>
              <TestimonialCard variant="outlined">
                <CardContent sx={{ textAlign: 'center' }}>
                  <IconWrapper>
                    <FormatQuoteIcon sx={{ fontSize: 50 }} />
                  </IconWrapper>
                  <Typography variant="body1" color={theme.palette.text.secondary} mt={2} fontStyle="italic">
                    {testimonial.quote}
                  </Typography>
                  <Typography variant="h6" mt={4} fontWeight="bold" color={theme.palette.text.primary}>
                    - {testimonial.name}
                  </Typography>
                  <Typography variant="subtitle2" color={theme.palette.text.secondary}>
                    {testimonial.institution}
                  </Typography>
                </CardContent>
              </TestimonialCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Testimonials;
