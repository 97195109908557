
export interface User {
    _id: string;
    fullname: string;
    email: string;
    role: string;
    picture: string;
  }
  
 export  interface UsersResponse {
    users: User[];
    totalPages: number;
    currentPage: number;
    totalUsers: number;
  }
  
  export interface ErrorResponse {
    error: string;
  }

  export enum UserRole {
    Unknown = "unknown",
    Client = "client",
    Admin = "admin",
    Student = "student",
    Author = "author",
  }
  
  export enum DifficultyLevel {
    EASY = "easy",
    MEDIUM = "medium",
    HARD = "hard",
  }
  
  export enum ProgrammingLanguage {
    JAVASCRIPT = "javascript",
    C = "c",
    CPP = "cpp",
    JAVA = "java",
    PYTHON = "python",
  }

  export interface Client {
    _id: string; // MongoDB ObjectId as a string
    name: string;
    email: string;
    isApproved: boolean;
    role: "client"; // Assuming role can only be "client"
    numberOfTestsPerUser: string | number; // Could be "Unlimited" or a numeric value as string
    numberOfUsers: number; // Represents the number of users
    validity: string; // ISO date string
    createdAt: string; // ISO date string
    updatedAt: string; // ISO date string
    __v: number; // Version key (used by Mongoose)
  }
  
  export enum ProblemStatus {
    DRAFT = "Draft",
    UNDER_REVIEW = "Under Review",
    APPROVED = "Approved",
    PUBLISHED = "Published",
    REJECTED = "Rejected",
  }

  export const problemStatusOptions = [
    { value: ProblemStatus.DRAFT, label: ProblemStatus.DRAFT },
    { value: ProblemStatus.UNDER_REVIEW, label: ProblemStatus.UNDER_REVIEW },
    { value: ProblemStatus.APPROVED, label: ProblemStatus.APPROVED },
    { value: ProblemStatus.PUBLISHED, label: ProblemStatus.PUBLISHED },
    { value: ProblemStatus.REJECTED, label: ProblemStatus.REJECTED },
    { value: "All", label: "All" },
  ];

  export enum QuestionType {
    Coding = 'coding',
    MCQ = 'mcq'
  }
  
  // Define Assessment Types
  export enum AssessmentType {
    Coding = 'coding',
    MCQ = 'mcq',
    Mixed = 'mixed'
  }