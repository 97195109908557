// src/components/Features.tsx
import React from "react";
import { Container, Grid, Typography, Paper, Box } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import CodeIcon from "@mui/icons-material/Code";
import BarChartIcon from "@mui/icons-material/BarChart";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";

const FeatureSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 0),
  backgroundColor: theme.palette.background.default,
  minHeight: "80vh", // Ensures the section occupies sufficient vertical space
  display: "flex",
  flexDirection: "column",
  justifyContent: "center", // Center content vertically
}));

const FeatureCard = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  height: "100%",
  padding: theme.spacing(4),
  borderRadius: "12px",
  textAlign: "center",
  boxShadow: theme.shadows[4],
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow: theme.shadows[6],
  },
}));

const IconContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  borderRadius: "50%",
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const Features: React.FC = () => {
  const theme = useTheme();

  return (
    <FeatureSection id="features">
      <Container>
        <Typography variant="h3" align="center" gutterBottom fontWeight="bold">
          Key Features
        </Typography>
        <Typography variant="h5" align="center" paragraph>
          Explore the core features that make CodeChatura an exceptional platform for educational assessments.
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <FeatureCard>
              <IconContainer>
                <CodeIcon fontSize="large" />
              </IconContainer>
              <Typography variant="h6" gutterBottom>
                Automated Grading
              </Typography>
              <Typography variant="body1">
                Efficiently grade coding assignments and MCQs with our automated system, ensuring consistency and accuracy.
              </Typography>
            </FeatureCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <FeatureCard>
              <IconContainer>
                <BarChartIcon fontSize="large" />
              </IconContainer>
              <Typography variant="h6" gutterBottom>
                Detailed Analytics
              </Typography>
              <Typography variant="body1">
                Gain insights into student performance with comprehensive analytics, helping you track progress and identify areas for improvement.
              </Typography>
            </FeatureCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <FeatureCard>
              <IconContainer>
                <IntegrationInstructionsIcon fontSize="large" />
              </IconContainer>
              <Typography variant="h6" gutterBottom>
                Seamless Integration
              </Typography>
              <Typography variant="body1">
                Easily integrate with your LMS or utilize our API for custom solutions tailored to your needs.
              </Typography>
            </FeatureCard>
          </Grid>
        </Grid>
      </Container>
    </FeatureSection>
  );
};

export default Features;
