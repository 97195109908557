import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  CardActions,
  TextField,
  Grid,
  Typography,
  Container,
  CircularProgress,
  Box,
  Snackbar,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Save, Clear } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { useParams, useNavigate } from "react-router-dom";
import { apiService } from "../../../services/Service"; // Ensure this path is correct
import { handleAuthError } from "../../../utils/authUtils"; // Ensure this path is correct

interface PlanFormData {
  title: string;
  startDate: Date;
  endDate: Date;
  numberOfUsers: number;
  numberOfAssessments: number;
  levels: string[];
  details: string;
  price: {
    usd: number;
    inr: number;
  };
}

const initialPlanData: PlanFormData = {
  title: "",
  startDate: new Date(),
  endDate: new Date(),
  numberOfUsers: 0,
  numberOfAssessments: 0,
  levels: [],
  details: "",
  price: {
    usd: 0,
    inr: 0,
  },
};

const UpdatePlan: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<PlanFormData>(initialPlanData);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  useEffect(() => {
    if (id) {
      setLoading(true);
      apiService
        .get(`/plans/${id}`)
        .then((response:any) => {
          const { data } = response;
          if (data) {
            setFormData({
              ...data,
              startDate: new Date(data.startDate),
              endDate: new Date(data.endDate),
            });
          } else {
            setSnackbarMessage("Failed to fetch plan data");
            setSnackbarOpen(true);
          }
        })
        .catch((error: any) => {
          setSnackbarMessage(
            error.response?.data.error || "Failed to fetch plan data"
          );
          setSnackbarOpen(true);
          handleAuthError(
            { error: error.response?.data.error },
            window.location.href
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = e.target;

    if (name.startsWith("price")) {
      const currency = name.split("-")[1];
      setFormData((prevData) => ({
        ...prevData,
        price: {
          ...prevData.price,
          [currency]: Number(value),
        },
      }));
    } else if (name === "levels") {
      const updatedLevels = value.split(",");
      setFormData((prevData) => ({
        ...prevData,
        levels: updatedLevels,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: type === "date" ? new Date(value) : value,
      }));
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage("");
  };

  const handleSubmit = () => {
    setLoading(true);
    apiService
      .put(`/plans/${id}`, formData)
      .then(() => {
        setLoading(false);
        setSnackbarMessage("Plan updated successfully!");
        setSnackbarOpen(true);
        navigate("/plans"); // Redirect after successful update
      })
      .catch((error: any) => {
        setLoading(false);
        setSnackbarMessage(
          error.response?.data.error || "Failed to update plan"
        );
        setSnackbarOpen(true);
        handleAuthError(
          { error: error.response?.data.error },
          window.location.href
        );
      });
  };

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ align: [] }],
      ["link"],
      [{ color: [] }, { background: [] }],
      ["image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "font",
    "list",
    "bullet",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "align",
    "link",
    "color",
    "background",
    "image",
    "clean",
  ];

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Card sx={{ boxShadow: 3, borderRadius: 2, padding: 2 }}>
        <CardContent>
          <Typography variant="h5" component="div" gutterBottom align="center">
            Update Plan
          </Typography>
          <Box component="form" noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Title"
                  variant="outlined"
                  fullWidth
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Start Date"
                  variant="outlined"
                  fullWidth
                  name="startDate"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  value={formData.startDate.toISOString().substring(0, 10)}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="End Date"
                  variant="outlined"
                  fullWidth
                  name="endDate"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  value={formData.endDate.toISOString().substring(0, 10)}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Number of Users"
                  variant="outlined"
                  fullWidth
                  name="numberOfUsers"
                  type="number"
                  value={formData.numberOfUsers}
                  onChange={handleInputChange}
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Number of Assessments"
                  variant="outlined"
                  fullWidth
                  name="numberOfAssessments"
                  type="number"
                  value={formData.numberOfAssessments}
                  onChange={handleInputChange}
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </Grid>
              {/* Levels Section */}
              <Grid item xs={12}>
                <Typography variant="h6">Levels</Typography>
                <Box display="flex" flexDirection="row" gap={2}>
                  {["LV1", "LV2", "LV3", "LV4"].map((level) => (
                    <FormControlLabel
                      key={level}
                      control={
                        <Checkbox
                          checked={formData.levels.includes(level)}
                          onChange={(e) => {
                            setFormData((prevData) => ({
                              ...prevData,
                              levels: e.target.checked
                                ? [...prevData.levels, level]
                                : prevData.levels.filter((l) => l !== level),
                            }));
                          }}
                        />
                      }
                      label={level}
                    />
                  ))}
                </Box>
              </Grid>
              {/* Details Section with ReactQuill */}
              <Grid item xs={12}>
                <Typography variant="h6">Details</Typography>
                <ReactQuill
                  value={formData.details}
                  onChange={(value: any) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      details: value,
                    }))
                  }
                  theme="snow"
                  placeholder="Enter plan details..."
                  modules={modules}
                  formats={formats}
                />
              </Grid>
              {/* Pricing Section */}
              <Grid item xs={12}>
                <TextField
                  label="Price (USD)"
                  variant="outlined"
                  fullWidth
                  name="price-usd"
                  type="number"
                  value={formData.price.usd}
                  onChange={handleInputChange}
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Price (INR)"
                  variant="outlined"
                  fullWidth
                  name="price-inr"
                  type="number"
                  value={formData.price.inr}
                  onChange={handleInputChange}
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <CardActions sx={{ justifyContent: "center", mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Save />}
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Update Plan"}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<Clear />}
            onClick={() => navigate("/plans")}
          >
            Cancel
          </Button>
        </CardActions>
      </Card>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Container>
  );
};

export default UpdatePlan;
