import React from "react";
import { Box, TextField, useTheme } from "@mui/material";

interface CustomFieldsProps {
  customInput: string;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomFields: React.FC<CustomFieldsProps> = ({
  customInput,
  onInputChange,
}) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  return (
    <Box
      sx={{
        mb: 2,
        backgroundColor: isDarkMode ? "#2e2e2e" : "#ffffff",
        p: 2,
        borderRadius: "10px",
        boxShadow: isDarkMode ? "0px 4px 8px rgba(255, 255, 255, 0.2)" : "0px 4px 8px rgba(0, 0, 0, 0.1)"
      }}
    >
      <TextField
        label="Custom Input"
        multiline
        rows={4}
        value={customInput}
        onChange={onInputChange}
        fullWidth
        variant="outlined"
        sx={{
          mb: 2,
          backgroundColor: isDarkMode ? "#3c3c3c" : "#ffffff",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: isDarkMode ? "#ffffff" : "#000000",
            },
            "&:hover fieldset": {
              borderColor: isDarkMode ? "#ffffff" : "#000000",
            },
          },
        }}
      />
    </Box>
  );
};

export default CustomFields;
