import React from "react";
import { Container, Grid, Typography, Button, Box, Paper, Chip } from "@mui/material";
import { styled } from "@mui/material/styles";
import LandingPageContent from "./LandingPageContent";
import { useTheme } from "@mui/material/styles";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
const HeroContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
  textAlign: "center",
  color: theme.palette.text.primary,
  position: "relative",
  backgroundColor: theme.palette.background.default,
}));

const StatsCard = ({ icon, title, value }: { icon: React.ReactNode; title: string; value: string }) => {
  const theme = useTheme();
  return (
    <Paper elevation={3} sx={{ p: 3, textAlign: "center", borderRadius: "8px", backgroundColor: theme.palette.background.paper }}>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}>
        {icon}
      </Box>
      <Typography variant="h5" fontWeight="bold">
        {value}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {title}
      </Typography>
    </Paper>
  );
};

const Hero: React.FC = () => {
  const theme = useTheme();

  return (
    <HeroContainer>
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          {/* Content Box */}
          <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
            <Box textAlign="center" sx={{ py: { xs: 5, md: 10 } }}>
              <Typography
                variant="h3"
                fontWeight="bold"
                gutterBottom
                sx={{ mb: 3, color: theme.palette.primary.main }}
              >
                Your Gateway to Efficient and Streamlined Assessments
              </Typography>
              <Typography
                variant="h6"
                mb={4}
                sx={{ maxWidth: "90%", mx: "auto", color: theme.palette.text.secondary }}
              >
                Our tool provides a seamless experience for colleges and clients to create, manage, and evaluate assessments with ease.
              </Typography>
            
              <Button
                variant="outlined"
                sx={{
                  mt: 2,
                  borderColor: theme.palette.primary.main,
                  color: theme.palette.primary.main,
                  px: 4,
                  py: 2,
                  fontSize: "1.25rem",
                  borderRadius: "8px",
                  "&:hover": {
                    borderColor: theme.palette.primary.dark,
                    color: theme.palette.primary.dark,
                  },
                }}
                size="large"
              >
                Learn More
              </Button>
            </Box>
          </Grid>
          {/* Landing Page Content */}
          <Grid item xs={12} md={6}>
            <LandingPageContent />
          </Grid>
        </Grid>

        {/* Statistics Section */}
        <Box sx={{ mt: 8 }}>
          <Typography variant="h4" fontWeight="bold" gutterBottom>
            Why Choose Us?
          </Typography>
          <Typography variant="body1" mb={4} color="text.secondary">
            Discover the impact we have made and how we stand out from the competition.
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} sm={4}>
              <StatsCard
                icon={<PeopleIcon sx={{ fontSize: 50, color: theme.palette.primary.main }} />}
                title="Institutions Served"
                value="300+"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <StatsCard
                icon={<BarChartIcon sx={{ fontSize: 50, color: theme.palette.primary.main }} />}
                title="Assessments Created"
                value="1,000+"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <StatsCard
                icon={<EmojiEventsIcon sx={{ fontSize: 50, color: theme.palette.primary.main }} />}
                title="Awards Won"
                value="10"
              />
            </Grid>
          </Grid>
        </Box>
       
      </Container>
    </HeroContainer>
  );
};
export default Hero;
