import React from "react";
import { Box, Container, Typography, Link, Grid, Divider } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";

// Styled Components
const FooterSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 0),
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
}));

const FooterLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
}));

const Footer: React.FC = () => {
  const theme = useTheme();

  return (
    <FooterSection component="footer">
      <Container maxWidth="lg">
        {/* Footer Top Section */}
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              About Us
            </Typography>
            <Typography variant="body2">
              CodeChatura offers innovative solutions for coding assessments and
              educational evaluations. Our platform is designed to help
              institutions streamline their testing processes and improve
              learning outcomes.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Contact Information
            </Typography>
            <Typography variant="body2">
              Email:{" "}
              <FooterLink href="mailto:info@codechatura.com">
                admin@codechatura.com
              </FooterLink>
              <br />
              Phone:{" "}
              <FooterLink href="tel:+1234567890">+91 9959950082</FooterLink>
              <br />
              Address: SY:11 WeWork Krishe Emerald, Laxmi Cyber City, Whitefields, Kondapur.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Follow Us
            </Typography>
            <Grid container spacing={2}>
              <Grid item>
                <Link href="#" color="inherit" aria-label="Twitter">
                  <TwitterIcon sx={{ fontSize: 30 }} />
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" color="inherit" aria-label="LinkedIn">
                  <LinkedInIcon sx={{ fontSize: 30 }} />
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" color="inherit" aria-label="Facebook">
                  <FacebookIcon sx={{ fontSize: 30 }} />
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" color="inherit" aria-label="GitHub">
                  <GitHubIcon sx={{ fontSize: 30 }} />
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider sx={{ my: 4, backgroundColor: theme.palette.divider }} />

        {/* Footer Bottom Section */}
        <Typography variant="body2" align="center">
          &copy; {new Date().getFullYear()} CodeChatura. All rights reserved.
        </Typography>
      </Container>
    </FooterSection>
  );
};

export default Footer;
