// src/components/HowItWorks.tsx
import React from "react";
import { Container, Grid, Typography, Paper, Box, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Signpost, Code, ShowChart } from "@mui/icons-material";

const StepCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: "12px",
  textAlign: "center",
  boxShadow: theme.shadows[6],
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary, // Ensures text color adapts to the theme
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow: theme.shadows[8],
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.primary.main, // Adapt to primary color
  color: theme.palette.primary.contrastText, // Ensures contrast text color
  borderRadius: "50%",
  width: 64,
  height: 64,
  marginBottom: theme.spacing(2),
}));

const HowItWorks: React.FC = () => {
  const theme = useTheme();

  return (
    <Box py={10} bgcolor={theme.palette.background.default} id="how-it-works">
      <Container>
        <Typography variant="h3" align="center" gutterBottom fontWeight="bold" color={theme.palette.text.primary}>
          How It Works
        </Typography>
        <Typography variant="h5" align="center" paragraph color={theme.palette.text.secondary}>
          Follow these steps to get started with CodeChatura and streamline your assessment process.
        </Typography>
        <Grid container spacing={4} mt={4} alignItems="stretch">
          <Grid item xs={12} md={4}>
            <StepCard>
              <IconWrapper>
                <Signpost fontSize="large" />
              </IconWrapper>
              <Typography variant="h5" gutterBottom fontWeight="bold">
                Step 1: Sign Up
              </Typography>
              <Typography variant="body1" color={theme.palette.text.secondary}>
                Register your institution and create a profile to start utilizing our platform for assessments.
              </Typography>
            </StepCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <StepCard>
              <IconWrapper>
                <Code fontSize="large" />
              </IconWrapper>
              <Typography variant="h5" gutterBottom fontWeight="bold">
                Step 2: Create Assessments
              </Typography>
              <Typography variant="body1" color={theme.palette.text.secondary}>
                Design and customize coding challenges and multiple-choice questions tailored to your curriculum.
              </Typography>
            </StepCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <StepCard>
              <IconWrapper>
                <ShowChart fontSize="large" />
              </IconWrapper>
              <Typography variant="h5" gutterBottom fontWeight="bold">
                Step 3: Analyze Results
              </Typography>
              <Typography variant="body1" color={theme.palette.text.secondary}>
                Utilize our detailed analytics to track performance and make informed decisions based on the data.
              </Typography>
            </StepCard>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default HowItWorks;
